const data = [
  {
    category: "NFT",
    items: [
      {
        title: "How to buy and invest NFT?",
        textArea: [
          "Choose an NFT marketplace. There are many NFT marketplaces available, such as OpenSea, Rarible, and NBA Top Shot. Each marketplace has its own strengths and weaknesses, so it's important to choose one that's right for you.",
          "Create a crypto wallet. You'll need a crypto wallet to store your NFTs. Some popular crypto wallets include MetaMask, Coinbase Wallet, and Trust Wallet.",
          "Buy cryptocurrency. Most NFTs are bought with Ethereum, so you'll need to buy some ETH. You can buy ETH at a cryptocurrency exchange, such as Coinbase or Binance.",
          "Transfer ETH to your crypto wallet. Once you've bought ETH, you'll need to transfer it to your crypto wallet. This can be done by following the instructions provided by your exchange or wallet provider.",
          "Find an NFT you want to buy. Browse the NFT marketplace of your choice and find an NFT that you're interested in.",
          "Make an offer. Once you've found an NFT you want to buy, you can make an offer to the seller. The seller may accept your offer, or they may counteroffer.",
          "Finalize the purchase. Once the seller has accepted your offer, you'll need to finalize the purchase. This will involve paying the seller's asking price and transferring the ETH from your crypto wallet to theirs.",
        ],
      },
      {
        title: "How secure is this tokens?",
        text: "NFT tokens are generally considered to be secure because they are stored on a blockchain, which is a secure and distributed ledger. This means that the NFT tokens are not stored in a single location, and they are protected by cryptography.",
        text2:
          "Here are some of the positive points about the security of NFT tokens:",
        textArea: [
          "Blockchain security: The blockchain technology that underlies NFTs is very secure. It is based on cryptography, which is a branch of mathematics that deals with secure communication. The blockchain is also decentralized, which means that it is not controlled by any single entity. This makes it difficult for hackers to attack the blockchain and steal NFT tokens.",
          "Immutability: The blockchain is an immutable ledger, which means that the data stored on it cannot be changed. This means that once an NFT is minted, its ownership cannot be changed. This makes NFTs a secure way to store ownership of digital assets.",
          "Buy cryptocurrency. Most NFTs are bought with Ethereum, so you'll need to buy some ETH. You can buy ETH at a cryptocurrency exchange, such as Coinbase or Binance.",
          "Transparency: The blockchain is a transparent ledger, which means that anyone can view the transactions that have taken place on it. This makes it difficult for fraudsters to use NFTs for illegal activities.",
        ],
      },
      {
        title: "Why we should choose NFT?",
        text: "There are many reasons why people choose to invest in NFTs. Here are some of the most common reasons:",
        textArea: [
          "Uniqueness: NFTs are unique digital assets, which means that no two NFTs are exactly alike. This makes them attractive to collectors who are looking for something that is truly one-of-a-kind.",
          "Scarcity: NFTs are often limited in supply, which can drive up their value. For example, the CryptoPunks collection is limited to 10,000 NFTs, and some of them have sold for millions of dollars.",
          "Verifiability: NFTs are stored on the blockchain, which is a secure and transparent ledger. This means that the ownership of an NFT can be easily verified, which can be a valuable asset for collectors and investors.",
          "Portability: NFTs can be easily transferred between wallets, which makes them easy to trade and sell. This makes them a more liquid asset than traditional collectibles, such as paintings or sculptures.",
          "Potential for profit: The NFT market is still in its early stages, so there is the potential for significant profits for early investors. However, it is important to remember that NFTs are a risky investment, and there is no guarantee of profits. ",
        ],
        text3:
          "Ultimately, the decision of whether or not to invest in NFTs is a personal one. There are many factors to consider, such as your investment goals, risk tolerance, and financial situation. It is important to do your own research and consult with a financial advisor before making any investment decisions.",
        text4: "Here are some of the potential benefits of investing in NFTs:",
        textArea2: [
          "Potential for high returns: The NFT market is still in its early stages, so there is the potential for significant profits for early investors. However, it is important to remember that NFTs are a risky investment, and there is no guarantee of profits.",
          "Digital ownership: NFTs provide a way to own digital assets in a secure and transparent way. This can be valuable for collectors and investors who want to ensure that they have the authentic version of a digital asset.",
          "Creativity: NFTs can be used to create new forms of art, music, and other creative content. This can open up new opportunities for artists and creators to monetize their work.",
          "Transparency: The blockchain technology that underlies NFTs provides a transparent and immutable record of ownership. This can be valuable for businesses and organizations that need to track the ownership of digital assets.",
        ],
      },
      {
        title: "Where should Buy or sell NFT?",
        text: "When choosing an NFT marketplace, it is important to consider the following factors:",
        textArea: [
          "The types of NFTs that are supported: Make sure the marketplace supports the type of NFTs you are interested in buying or selling.",
          "The fees charged: The fees charged by NFT marketplaces can vary, so it is important to compare different marketplaces before making a decision.",
          "The security of the marketplace: Make sure the marketplace is secure and that your NFTs will be safe.",
          "The user interface: The user interface of the marketplace should be easy to use and navigate.",
        ],
        text3:
          "It is also important to do your own research on the NFTs you are interested in buying or selling. Make sure you understand the value of the NFT and the risks involved in buying or selling it.",
      },
      {
        title: "What is your contact address?",
        text: "An NFT contract address is a unique identifier that is used to identify an NFT on the blockchain. It is a long string of characters that starts with 0x. The contract address is used to store the metadata for the NFT, such as the image or video file, and the ownership information.",
        text2:
          "The contract address is also used to facilitate the transfer of ownership of the NFT. When an NFT is sold, the new owner's wallet address is added to the contract address. This ensures that the NFT can only be transferred to the new owner.",
        text3:
          "To find the contract address of an NFT, you can use an NFT explorer. An NFT explorer is a website that allows you to view the metadata and ownership information for an NFT.",
        text4:
          "AlienPlanetarium contract address-0x43B9255736775f9187d1eBD531ee6c47B847C9c4",
      },
      {
        title: "What is NFT?",
        text: "NFT stands for non-fungible token. A non-fungible token is a unique digital asset that cannot be replaced by another token. Each NFT has its own unique identification code and metadata, which makes it impossible to counterfeit or duplicate.",
        text2:
          "NFTs are stored on a blockchain, which is a distributed ledger that records transactions. This makes it possible to track the ownership of an NFT and to verify its authenticity.",
        text5:
          "NFTs can be used to represent a variety of digital assets, such as:",
        textArea: [
          "Artwork",
          "Music",
          "Videos",
          "In-game items",
          "Collectibles",
          "Virtual real estate",
        ],
        text3: "NFTs can also be used to represent real-world assets, such as:",
        textArea2: ["Property deeds", "Birth certificates", "Medical records"],
        text6:
          "NFTs are still a relatively new technology, but they have the potential to revolutionize the way we own and trade digital assets.",
        text7: "Here are some of the benefits of using NFTs:",
        textArea3: [
          "Uniqueness: Each NFT is unique and cannot be replaced by another token. This makes them ideal for representing digital assets that are also unique, such as artwork or collectibles.",
          "Immutability: NFTs are stored on a blockchain, which is a secure and tamper-proof ledger. This means that the ownership of an NFT cannot be changed or tampered with.",
          "Transparency: The ownership of an NFT can be easily tracked on the blockchain. This makes it a more transparent way to own and trade digital assets.",
          "Scarcity: NFTs can be created in limited quantities, which can give them value as collectibles.",
        ],
        text8: "However, there are also some risks associated with NFTs:",
        textArea4: [
          "Fraud: There have been cases of fraud involving NFTs. It is important to do your research before buying or selling an NFT.",
          "Volatility: The value of NFTs can fluctuate wildly. This means that you could lose money if you buy an NFT that loses its value.",
          "Energy consumption: The creation and trading of NFTs can consume a lot of energy. This is a concern for some people who are concerned about the environmental impact of NFTs.",
        ],
        text9:
          "Overall, NFTs are a new and emerging technology with the potential to revolutionize the way we own and trade digital assets. However, there are also some risks associated with NFTs that should be considered before investing in them.",
      },
    ],
  },
]

export default data
