import Dropdown from "react-bootstrap/Dropdown"
import { BsXLg } from "react-icons/bs"
import { FaDiscord, FaTwitter, FaWallet } from "react-icons/fa"
import openseaIcon from "../../../../assets/images/icon/opensea.svg"
import Button from "../../../../common/button"
import { isMetaMaskInstalled } from "../../../../config"
import { useModal } from "../../../../utils/ModalContext"
import MobileMenuStyleWrapper from "./MobileMenu.style"
import { Link } from "react-router-dom"

const MobileMenu = ({ mobileMenuhandle }) => {
  const {
    walletModalHandle,
    metamaskModalHandle,
    account,
    disconnectWalletFromApp,
  } = useModal()
  // const [isSubmenu, setSubmenu] = useState(false)

  // const handleSubmenu = () => {
  //   setSubmenu(!isSubmenu)
  // }

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str
  }

  const handleWalletConnect = async () => {
    if (!isMetaMaskInstalled()) {
      metamaskModalHandle()
    } else {
      walletModalHandle()
    }
  }
  return (
    <MobileMenuStyleWrapper className='bithu_mobile_menu'>
      <div className='bithu_mobile_menu_content'>
        <div className='mobile_menu_logo'>
          {/* <img className='bithu_logo' src={logo} alt=' logo' /> */}
          <button
            className='mobile_menu_close_btn'
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className='bithu_mobile_menu_list'>
          <ul>
            <li className='mobile_menu_hide'>
              <Link to='/home'>Home</Link>
            </li>
            <li className='mobile_menu_hide'>
              <Link to='/about'>About</Link>
            </li>
            <li className='mobile_menu_hide'>
              <Link to='/#/roadmap'>Roadmap</Link>
            </li>
            <li className='mobile_menu_hide'>
              <Link to='/faq'>FAQ</Link>
            </li>
            {/* <li className='submenu mobile_submenu' onClick={handleSubmenu}>
              <a href='# '>Pages +</a>
              <ul
                className={`sub_menu_list mobile_sub_menu_list ${
                  isSubmenu === true && "submenu_hovered"
                }`}
              >
                <li className='mobile_menu_hide'>
                  <a href='/'>Home One</a>
                </li>
                <li className='mobile_menu_hide'>
                  <a href='/home-two'>Home Two</a>
                </li>
                <li className='mobile_menu_hide'>
                  <a href='/home-three'>Home Three</a>
                </li>
                <li className='mobile_menu_hide'>
                  <a href='/blogs'>Latest Blog</a>
                </li>
                <li className='mobile_menu_hide'>
                  <a href='/post'>Blog Details</a>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
        <div className='mobile_menu_social_links'>
          <a href='# '>
            <img src={openseaIcon} alt=' social icon' />
          </a>
          <a href='# '>
            <FaTwitter />
          </a>
          <a href='# '>
            <FaDiscord />
          </a>
        </div>
        {account ? (
          <Dropdown>
            <Dropdown.Toggle
              variant='secondary'
              id='dropdown-basic'
              className='connect_btn'
            >
              {substr(account.toString(), 15)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href='# '
                onClick={() => disconnectWalletFromApp()}
              >
                Disconnect
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Button
            sm
            variant='hovered'
            className='connect_btn'
            onClick={() => handleWalletConnect()}
          >
            <FaWallet />
            Connect
          </Button>
        )}
      </div>
    </MobileMenuStyleWrapper>
  )
}

export default MobileMenu
