import { FiPlus, FiMinus } from "react-icons/fi"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
// import SectionTitle from "../../../../common/sectionTitle";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "../../../../common/accordion/Accordion"
import data from "../../../../assets/data/faqDataV3"
import "react-tabs/style/react-tabs.css"
import FAQStyleWrapper from "./Faq.style"

const FAQ = () => {
  return (
    <FAQStyleWrapper className='bithu_faq_sect' id='faq'>
      <div className='container'>
        <div className='faq_content'>
          <Tabs>
            <TabList>
              {data?.map((faq, idx) => (
                <Tab key={idx}>{faq.category}</Tab>
              ))}
            </TabList>

            {data?.map((faq, idx) => (
              <TabPanel key={idx}>
                <Accordion className='faq_questions'>
                  {faq.items?.map((item, i) => (
                    <AccordionItem key={i}>
                      <AccordionTitle>
                        <h5>{item.title}</h5>
                        <IconWrapper>
                          <OpenIcon>
                            <FiMinus />
                          </OpenIcon>
                          <CloseIcon>
                            <FiPlus />
                          </CloseIcon>
                        </IconWrapper>
                      </AccordionTitle>
                      <AccordionBody>
                        <div style={{ paddingBottom: "10px" }}>
                          {item?.text && <p>{item?.text}</p>}
                          {item?.text2 && <p>{item?.text2}</p>}
                          {item?.text5 && <p>{item?.text5}</p>}
                          {item?.textArea &&
                            item?.textArea?.map((item, i) => (
                              <p style={{ paddingBottom: "10px" }}>
                                &#x2022; {item}
                              </p>
                            ))}
                          {item?.text3 && <p>{item?.text3}</p>}
                          {item?.text4 && <p>{item?.text4}</p>}
                          {item?.textArea2 &&
                            item?.textArea2?.map((item, i) => (
                              <p style={{ paddingBottom: "10px" }}>
                                &#x2022; {item}
                              </p>
                            ))}
                          {item?.text6 && <p>{item?.text6}</p>}
                          {item?.text7 && <p>{item?.text7}</p>}
                          {item?.textArea3 &&
                            item?.textArea3?.map((item, i) => (
                              <p style={{ paddingBottom: "10px" }}>
                                &#x2022; {item}
                              </p>
                            ))}
                          {item?.text8 && <p>{item?.text8}</p>}
                          {item?.textArea4 &&
                            item?.textArea4?.map((item, i) => (
                              <p style={{ paddingBottom: "10px" }}>
                                &#x2022; {item}
                              </p>
                            ))}
                          {item?.text9 && <p>{item?.text9}</p>}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </Accordion>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </FAQStyleWrapper>
  )
}

export default FAQ
