import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore/lite"
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyC670Hb4IRqhXrHc7v0a0iTxlQRMznjWuU",
  authDomain: "alienplanetarium-63f4f.firebaseapp.com",
  projectId: "alienplanetarium-63f4f",
  storageBucket: "alienplanetarium-63f4f.appspot.com",
  messagingSenderId: "1072382020859",
  appId: "1:1072382020859:web:aa6d50e8e3b28b9671745e",
  measurementId: "G-CRBRC27YFK",
}

const app = initializeApp(firebaseConfig)
const firestore = getFirestore(app)
const database = getDatabase(app)

export { app, firestore, database }
