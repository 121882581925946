import React from "react"
import ReactDOM from "react-dom/client"
import { HashRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import App from "./app/App"
import ContextProvider from "./utils/ContextProvider"
import { TransactionsProvider } from "./context/transactionContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ContextProvider>
    <HashRouter>
      <TransactionsProvider>
        <App />
      </TransactionsProvider>
      ,
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </HashRouter>
  </ContextProvider>
)
