import SectionTitle from "../../../../common/sectionTitle"

// import data from "../../../../assets/data/about/dataV2"

import AboutStyleWrapper from "./About.style"

const MintingInformation = () => {
  // const { aboutDescription1, aboutDescription2, artists } = data;
  return (
    <AboutStyleWrapper id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='v1_about_us_right_sect row'>
              <SectionTitle title='MINTING INFORMATION' />
              <div className='v1_about_us_right_text row'>
                <div className='col-12 col-md-6'>
                {/* <h4
                  className='banner-title text-uppercase text-white'
                  style={{
                    marginBottom: ".5rem",
                    textTransform: "capitalize !important",
                  }}
                >
                  Images will reveal on:
                </h4>
                <ul style={{ color: "white", marginBottom: "1rem" }}>
                  <li>27.10.2023(10 A.M UTC)</li>
                </ul> */}

                <h4
                  className='banner-title text-uppercase text-white'
                  style={{
                    marginBottom: ".5rem",
                    textTransform: "capitalize !important",
                  }}
                >
                 💥 Public Minting:
                </h4>
                <ol style={{ color: "white", marginBottom: "1rem" }}>
                  <li>Start Date: 15.11.2023(10 A.M UTC)</li>
                  <li>Price: 0.0059 ETH</li>
                </ol>
                <h4
                  className='banner-title text-uppercase text-white'
                  style={{
                    marginBottom: ".5rem",
                    textTransform: "capitalize !important",
                  }}
                >
                  💥 Whitelist Minting:
                </h4>
                <ul style={{ color: "white", marginBottom: "1rem" }}>
                  <li>Start Date: 14.11.2023(10 A.M UTC)</li>
                  {/* <li>End Date: 20.10.2023(10 A.M UTC)</li> */}
                  <li>Price: 0.0050 ETH</li>
                </ul>
                </div>
                <div className='col-12 col-md-6'>
                <h4
                  className='banner-title text-uppercase text-white'
                  style={{
                    marginBottom: ".5rem",
                    textTransform: "capitalize !important",
                  }}
                >
                  💥 Discounts
                </h4>
                <ul style={{ color: "white", marginBottom: "1rem" }}>
                  <li>✅ Volume discount: <br/> Mint 4 or more together for 10% off <s>0.0059</s> 0.0053 ETH each</li>
                  <li>✅ All NFT buyers get early access to our next project and exclusive content.</li>
                  <li>✅ 4 or more NFT holders will get early access and 10% discount for our next project.</li>
                  <li>✅ 10 or more NFT holders will get WL spot for our next project.</li>
                  {/* <li>Next 500 to 1000 mints 10% off</li> */}
                  {/* <li>
                    All Alien NFT holders get early access with 5% off on future NFT drops.
                  </li>
                  <li>
                    Multiple mint discount: 15% discount for 3
                    or more NFT holders on future NFT drops 
                  </li> */}
                </ul>
                </div>
                <div className="col-12">
                <h2 className='banner-title text-uppercase text-white'>💥 Utilities :</h2>
                </div>
                <div className="col-6">
                    <p className='text-white'>✅ Access to our exclusive content.</p>
                    <p className='text-white'>✅ Early access to all upcoming projects.</p>
                    <p className='text-white'>✅ Chance to win our Exclusive and rare NFTs.</p>
                    <p className='text-white'>✅ Exclusive Airdrops and Giveaway.</p>
                    <p className='text-white'>✅ A portal with exclusive game access for all.</p>
                  </div>
                  <div className="col-6">
                    <p className='text-white'>✅ Play to win cash prizes with our games.</p>
                    <p className='text-white'>✅ Private Sale & Airdrops to all holders.</p>
                    <p className='text-white'>✅ Free Mints on all upcoming projects side collection.</p>
                    <p className='text-white'>✅ 4 or more NFT holders will get early access and 10% discount for our next project.</p>
                    <p className='text-white'>✅ 10 or more NFT holders will get WL spot for our next project.</p>

                  </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="about-left-item">
              <SectionTitle
                subtitle="Meet The Artists"
                className="about_section_title"
              />
              <div className="ab-content">
                {artists?.map((item, i) => (
                  <div key={i} className="ab-item-box mr-30">
                    <img
                      className="author-thumbnail"
                      src={item.thumb}
                      alt=" nft about"
                    />
                    <div className="ab-text pt-20">
                      <h5>
                        {item.name}
                        <img src={item.icon} alt=" nft about icon" />
                      </h5>
                    </div>
                    <span>{item.role}</span>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </AboutStyleWrapper>
  )
}

export default MintingInformation
