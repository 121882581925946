// import { FaRegComments } from "react-icons/fa"

import { Link } from "react-router-dom"
import BlogItemStyleWrapper from "./BlogItem.style"

const BlogItem = ({
  thumbnail,
  title,
  timeStamp,
  categories,
  excerpt,
  link,
}) => {
  return (
    <BlogItemStyleWrapper className='single_blog_item'>
      <div className='blog_thumb'>
        <Link to={link}>
          <img src={thumbnail} alt=' nft blog' />
        </Link>
      </div>
      <div className='blog_content'>
        <div className='blog_meta'>
          {categories?.map((category, i) => (
            <Link key={i} to={link} className='category_title'>
              {i > 0 ? ", " : ""}
              {category.title}
            </Link>
          ))}
          <span>.</span>
          <span className='time_stamp'>{timeStamp}</span>
        </div>

        <Link className='blog_title' to={link}>
          {" "}
          {title}
        </Link>
        <p>{excerpt} </p>
        <div className='blog_footer'>
          <div className='read_more_btn'>
            <Link to={link}> Read more</Link>
          </div>
          {/* <div className="comment_icon">
            <a href="# ">
              {" "}
              <FaRegComments />
            </a>{" "}
            05
          </div> */}
        </div>
      </div>
    </BlogItemStyleWrapper>
  )
}

export default BlogItem
