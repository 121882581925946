import { FiMinus, FiPlus } from "react-icons/fi"
import { TabPanel, Tabs } from "react-tabs"
import data from "../../../../assets/data/faqDataV3"
import faqBgThumb from "../../../../assets/images/bg/faq_bg_wattermark.png"
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionTitle,
  CloseIcon,
  IconWrapper,
  OpenIcon,
} from "../../../../common/accordion/Accordion"
import SectionTitle from "../../../../common/sectionTitle"
import FAQStyleWrapper from "./Faq.style"
import { useModal } from "../../../../utils/ModalContext"

const FAQ = () => {
  const { askModalHandle } = useModal()
  return (
    <FAQStyleWrapper className='bithu_faq_sect' id='faq'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <SectionTitle
              title='FREQUENTLY ASKED QUESTIONS'
              subtitle='QUESTIONS & ANSWERS'
              className='section_title'
            />

            <div className='bithu_title_text'>
              <span>Don't get your answer?</span>
              <span style={{ display: "flex" }}>
                Fell free to leave a message,&nbsp;
                <span
                  onClick={askModalHandle}
                  style={{ cursor: "pointer", color: "#00ffa3", zIndex: "1" }}
                >
                  {" "}
                  Ask Here!
                </span>
              </span>
            </div>
          </div>

          <div className='col-md-6'>
            <Tabs>
              {data?.map((faq, idx) => (
                <TabPanel key={idx}>
                  <Accordion className='faq_questions'>
                    {faq.items?.map((item, i) => (
                      <AccordionItem key={i}>
                        <AccordionTitle>
                          <h5>{item.title}</h5>
                          <IconWrapper>
                            <OpenIcon>
                              <FiMinus />
                            </OpenIcon>
                            <CloseIcon>
                              <FiPlus />
                            </CloseIcon>
                          </IconWrapper>
                        </AccordionTitle>
                        <AccordionBody>
                          <div style={{ paddingBottom: "10px" }}>
                            {item?.text && <p>{item?.text}</p>}
                            {item?.text2 && <p>{item?.text2}</p>}
                            {item?.text5 && <p>{item?.text5}</p>}
                            {item?.textArea &&
                              item?.textArea?.map((item, i) => (
                                <p style={{ paddingBottom: "10px" }}>
                                  &#x2022; {item}
                                </p>
                              ))}
                            {item?.text3 && <p>{item?.text3}</p>}
                            {item?.text4 && <p>{item?.text4}</p>}
                            {item?.textArea2 &&
                              item?.textArea2?.map((item, i) => (
                                <p style={{ paddingBottom: "10px" }}>
                                  &#x2022; {item}
                                </p>
                              ))}
                            {item?.text6 && <p>{item?.text6}</p>}
                            {item?.text7 && <p>{item?.text7}</p>}
                            {item?.textArea3 &&
                              item?.textArea3?.map((item, i) => (
                                <p style={{ paddingBottom: "10px" }}>
                                  &#x2022; {item}
                                </p>
                              ))}
                            {item?.text8 && <p>{item?.text8}</p>}
                            {item?.textArea4 &&
                              item?.textArea4?.map((item, i) => (
                                <p style={{ paddingBottom: "10px" }}>
                                  &#x2022; {item}
                                </p>
                              ))}
                            {item?.text9 && <p>{item?.text9}</p>}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
        <div className='faq_bg_wattermark'>
          <img src={faqBgThumb} alt=' nft faq' />
        </div>
      </div>
    </FAQStyleWrapper>
  )
}

export default FAQ
