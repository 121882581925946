import { FiCheck, FiMinus } from "react-icons/fi"
// import thumb1 from "../../images/nft/roadmap_item1.png"
// import thumb2 from "../../images/nft/roadmap_item2.png"
// import thumb3 from "../../images/nft/roadmap_item3.png"
// import thumb4 from "../../images/nft/roadmap_item4.png"
import thumb1 from "../../images/AP/IMG_5214.JPG"
import thumb2 from "../../images/AP/IMG_5218.JPG"
import thumb3 from "../../images/AP/IMG_5219.JPG"
import thumb4 from "../../images/AP/IMG_5220.JPG"
const data = [
  {
    title: "🌌 Phase 1: Genesis (25% Sold)",
    thumb: thumb1,
    features: [
      {
        icon: <FiCheck />,
        text: "Witness the birth of our mesmerizing Alien NFT collection.",
      },
      {
        icon: <FiCheck />,
        text: "Join a growing community of enthusiasts and early adopters.",
      },
      {
        icon: <FiCheck />,
        text: "Be thrilled by exclusive NFT drops, each one a masterpiece.",
      },
      {
        icon: <FiCheck />,
        text: "Conduct exciting giveaways ,NFT Drops ,VIP Access and airdrops for our early supporters.",
      },
      {
        icon: <FiCheck />,
        text: "Conduct Contests,Virtual Events and Virtual Meet-ups(Q&A,AMA etc)",
      },
      // {
      //   icon: <FiCheck />,
      //   text: "Pre-Booking Announcement: We'll unveil the pre-booking phase, allowing early supporters to secure their spots and get exclusive access to our pre-sale.",
      // },
      // {
      //   icon: <FiCheck />,
      //   text: "Our official website will go live, providing a centralized hub for all updates, information, and NFT releases and Start minting.",
      // },
    ],
  },

  {
    title: "🌠 Phase 2: Galactic Expansion (50% Sold)",
    thumb: thumb2,
    features: [
      {
        icon: <FiCheck />,
        text: "Traverse new cosmic realms with rare and legendary aliens.",
      },
      {
        icon: <FiCheck />,
        text: "Embrace collaborations with talented artists and creators.",
      },
      {
        icon: <FiCheck />,
        text: "Experience a universe of excitement with captivating events.",
      },
    ],
  },

  {
    title: "💫 Phase 3: Stellar Advancements (75% Sold)",
    thumb: thumb3,
    features: [
      {
        icon: <FiMinus />,
        text: "Launching our groundbreaking interstellar adventure game.",
      },
      {
        icon: <FiMinus />,
        text: "Reward our loyal holders with exclusive staking benefits.",
      },
      {
        icon: <FiMinus />,
        text: "Unite with other NFT projects for boundless exploration.",
      },
    ],
  },

  {
    title: "🪐 Phase 4: Cosmic Conquest (90% Sold)",
    thumb: thumb4,
    features: [
      {
        icon: <FiMinus />,
        text: "A sneak peek into our thrilling, immersive game universe.",
      },
      {
        icon: <FiMinus />,
        text: "Compete in exhilarating tournaments and community challenges.",
      },
      {
        icon: <FiMinus />,
        text: "Making a difference with charitable endeavors beyond our world.",
      },
    ],
  },
  {
    title: "🌠 Phase 5: Astral Triumph (100% Sold)",
    thumb: thumb1,
    features: [
      {
        icon: <FiMinus />,
        text: "Celebrate the crowning moment of our epic odyssey.",
      },
      {
        icon: <FiMinus />,
        text: "Embark on an unforgettable journey within our game.",
      },
      {
        icon: <FiMinus />,
        text: "Together, we forge an indelible mark on the cosmos of NFTs!",
      },
      {
        icon: <FiMinus />,
        text: "🚀 The journey is just beginning, and it's fueled by your passion and support. Let's soar among the stars and create an unparalleled legacy in the NFT universe! 🌌✨",
      },
    ],
  },
]

export default data
