import { addDoc, collection } from "firebase/firestore/lite"
import data from "../../../../assets/data/footer/footerDataV3"
import FooterBottom from "./footerBottom/FooterBottom"
// import footerLogo from "../../../../assets/images/logo.png"
import btnArrow from "../../../../assets/images/blog/arrow-icon.png"

import { useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { firestore } from "../../../../utils/firebaseConfig"
import FooterStyleWrapper from "./Footer.style"
const Footer = () => {
  const [email, setEmail] = useState("")
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const newsletterCollectionRef = collection(firestore, "newsletter")
  const handleClick = async () => {
    try {
      const docRef = await addDoc(newsletterCollectionRef, { email })
      console.log("Entry added with ID: ", docRef.id)
      toast.success("You Have successfully subscribed to our news letter!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setEmail("")
    } catch (error) {
      console.error("Error adding entry: ", error)
    }
  }
  return (
    <FooterStyleWrapper>
      <div className='top-footer-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='footer_image'>
                <a href='# '>
                  {/* <img src={footerLogo} alt=" nft footer logo" /> */}
                </a>
                <p>
                  {/*  is great solution for launch your NFT for minting. It
                  uses a dictionary over 200 Latin words, combined with a
                  handful. */}
                </p>
              </div>
            </div>

            {/* link widgets  */}
            {data?.map((menu, i) => (
              <div key={i} className=' col-sm-6 col-md-3 col-lg-2 link-widgets'>
                <div className='footer-menu'>
                  <h5 className='menu-title'>{menu.widgetName}</h5>
                  <ul className='menu-list'>
                    {menu.items?.map((item, i) => (
                      <li key={i}>
                        <Link to={item.url}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
            <div className='col-md-6 col-lg-4'>
              <div className='footer-menu footer_newsletter'>
                <h5 className='menu-title'>Subscribe Newsletter</h5>
                <div className='form-box'>
                  <input
                    type='text'
                    name='text'
                    placeholder='Email address'
                    required
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <button onClick={handleClick}>
                    <img src={btnArrow} alt=' nft arrow' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom  */}
      <FooterBottom />
    </FooterStyleWrapper>
  )
}

export default Footer
