import thumb1 from "../../images/blog/nft/1.png"
import thumb2 from "../../images/blog/nft/2.png"
import thumb3 from "../../images/blog/nft/3.png"
import thumb4 from "../../images/blog/nft/4.png"
import thumb5 from "../../images/blog/nft/5.png"
import thumb6 from "../../images/blog/nft/6.png"
import thumb7 from "../../images/blog/nft/7.png"
import thumb8 from "../../images/blog/nft/8.png"
// import thumb9 from "../../images/blog/blog-img9.png"

const data = {
  posts: [
    {
      thumbnail: thumb1,
      author: "INNA MOUAZ",
      title: "Unleashing the Power of NFTs: Redefining Ownership and Value",
      excerpt:
        "In a world where digital content is abundant, the concept of true ownership often feels elusive. However, Non-Fungible Tokens (NFTs) have emerged as a groundbreaking solution that promises to revolutionize the way we own, trade, and interact with digital assets. In this blog, we will delve into the captivating realm of NFTs, exploring their transformative impact on the art market, the metaverse, and beyond.",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "NFT",
          url: "#",
        },
      ],
      link: "/blog-1",
    },
    {
      thumbnail: thumb2,
      author: "INNA MOUAZ",
      title: "NFTs and the Metaverse: Where Digital Possibilities Meet Reality",
      excerpt:
        "The concept of the metaverse has captured the imaginations of millions, promising a seamless blend of digital and physical realities. Central to this virtual universe is the rise of Non-Fungible Tokens (NFTs), which have become a driving force in shaping the metaverse's landscape. In this blog, we embark on a thrilling journey into the metaverse, exploring the transformative power of NFTs in creating immersive experiences, virtual economies, and a new era of interconnectedness.",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "GAMMING",
          url: "#",
        },
      ],
      link: "/blog-2",
    },
    {
      thumbnail: thumb3,
      author: "INNA MOUAZ",
      title: "What are NFTs and how do they work?",
      excerpt:
        "NFT stands for non-fungible token. In the world of cryptocurrency, fungible tokens are interchangeable. For example, one Bitcoin is equal to another Bitcoin. However, NFTs are unique and cannot be replaced. This makes them ideal for representing digital assets such as artwork, music, and videos.",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "METAVERSE",
          url: "#",
        },
        {
          title: "NFT",
          url: "#",
        },
      ],
      link: "/blog-3",
    },
    {
      thumbnail: thumb4,
      author: "INNA MOUAZ",
      title: "The future of NFTs",
      excerpt:
        "NFTs are a relatively new technology, but they have already captured the imagination of many people. They have the potential to revolutionize the way we own and trade digital assets, and they are already being used in a variety of industries, including art, gaming, and music.",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "META",
          url: "#",
        },
      ],
      link: "/blog-4",
    },
    {
      thumbnail: thumb5,
      author: "INNA MOUAZ",
      title: "How to create your own NFT",
      excerpt:
        "If you are interested in creating your own NFT, there are a few things you will need to do..",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "IGO",
          url: "#",
        },
      ],
      link: "/blog-5",
    },
    {
      thumbnail: thumb6,
      author: "INNA MOUAZ",
      title: "The pros and cons of NFTs",
      excerpt:
        "NFTs are a new and innovative technology, but they also have some potential drawbacks. Here is a look at some of the pros and cons of NFTs..",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "CRYPTO",
          url: "#",
        },
      ],
      link: "/blog-6",
    },
    {
      thumbnail: thumb7,
      author: "INNA MOUAZ",
      title: "The environmental impact of NFTs",
      excerpt:
        "The environmental impact of NFTs is a topic of much debate. Some people argue that NFTs are a waste of energy and resources, while others argue that they have a negligible impact.",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "Metaverse",
          url: "#",
        },
      ],
      link: "/blog-7",
    },
    {
      thumbnail: thumb8,
      author: "INNA MOUAZ",
      title: "The legal implications of NFTs",
      excerpt:
        "The legal implications of NFTs are still being debated. Some people argue that NFTs are simply digital assets, and that they are not subject to any specific laws. Others argue that NFTs could be considered securities, and that they would be subject to securities laws.",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "NFT",
          url: "#",
        },
        {
          title: "GAMMING",
          url: "#",
        },
      ],
      link: "/blog-8",
    },
  ],
}

export default data
