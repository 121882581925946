import { useModal } from "../../../../utils/ModalContext"
import { FiShare2 } from "react-icons/fi"
// import { FaCalendarAlt } from "react-icons/fa"
import BlogDetailsStyleWrapper from "./BlogDestails.style"
// components
import Tag from "../tag/Tag"
// import Comment from "../comment/Comment"
// import CommentBoxment from "../commentBox/CommentBox"
import Sidebar from "../sidebar/Sidebar"

//images
import postThumb from "../../../../assets/images/blog/details/4.png"
// import postThumb2 from "../../../../assets/images/blog/blog-img2.png"
// import postThumb3 from "../../../../assets/images/blog/blog-img3.png"
// import avatarIcon from "../../../../assets/images/blog/user.png"
import postThumb4 from "../../../../assets/images/blog/blog.png"
import postThumb5 from "../../../../assets/images/blog/blog2.png"
import { Link } from "react-router-dom"

const BlogDetails4 = () => {
  const { shareModalHandle } = useModal()
  return (
    <BlogDetailsStyleWrapper>
      <div className='blog_post_details_wrapper'>
        <div className='container'>
          <div className='row'>
            {/* post details col  */}
            <div className='col-lg-8 col-md-12'>
              <div className='post_thumbnail'>
                <img src={postThumb} alt=' nft blog' />
              </div>
              {/* <div className='blog_post_meta'>
                <a href='# ' className='post_author'>
                  <img src={avatarIcon} alt='blog post avatar' />
                  Inna Mouaz
                </a>
                <span>
                  {" "}
                  <FaCalendarAlt /> 18 FEB, 2022
                </span>
              </div> */}
              <h1 className='post_title'>The future of NFTs</h1>
              <div className='blog_description'>
                <div>
                  <p>
                    NFTs are a relatively new technology, but they have already
                    captured the imagination of many people. They have the
                    potential to revolutionize the way we own and trade digital
                    assets, and they are already being used in a variety of
                    industries, including art, gaming, and music.
                  </p>
                </div>
                <h3>
                  So, what does the future hold for NFTs? Here are a few
                  possibilities:
                </h3>
                <h4>NFTs will become more mainstream:</h4>
                <p>
                  As more people become aware of NFTs and their potential, they
                  will become more popular and mainstream. This could lead to
                  increased adoption by businesses and consumers, and it could
                  also lead to higher prices for NFTs.
                </p>
                <p>
                  NFTs will be used in new and innovative ways: NFTs are still
                  in their early stages of development, and there are many new
                  and innovative ways that they could be used. For example, NFTs
                  could be used to represent real-world assets, such as property
                  or cars. They could also be used to create new forms of
                  digital art or gaming experiences.
                </p>
                <p>
                  NFTs will become more regulated: As the NFT market grows, it
                  is likely that governments will start to regulate NFTs. This
                  could help to protect consumers and investors, and it could
                  also help to legitimize the NFT market.
                </p>
                <p>
                  Overall, the future of NFTs is very bright. They have the
                  potential to revolutionize the way we own and trade digital
                  assets, and they are already being used in a variety of
                  industries. It will be interesting to see how NFTs develop in
                  the years to come.
                </p>
                <h3>
                  Here are some of the challenges that NFTs will need to
                  overcome in order to reach their full potential:
                </h3>
                <h4>Lack of awareness:</h4>
                <p>
                  Many people are still not aware of NFTs or their potential.
                  This will need to change if NFTs are to become mainstream.
                </p>
                <h4>High cost:</h4>
                <p>
                  The cost of minting and buying NFTs can be high, which can
                  make them inaccessible to some people. This will need to be
                  addressed if NFTs are to reach a wider audience. Lack of
                  regulation: The NFT market is still relatively unregulated,
                  which can make it risky for investors. This will need to be
                  addressed before NFTs can become truly mainstream. Despite
                  these challenges, I believe that the future of NFTs is very
                  bright. They have the potential to revolutionize the way we
                  own and trade digital assets, and they are already being used
                  in a variety of industries. I am excited to see how NFTs
                  develop in the years to come.
                </p>

                <p>
                  I hope this blog post has helped you to understand what NFTs
                  are and how they work. If you have any questions, please feel
                  free to leave a comment below.
                </p>
              </div>
              {/* // tags  */}
              <div className='post_tags_list'>
                <Tag />
                <div className='share_butn'>
                  <a href='# ' onClick={(e) => shareModalHandle(e)}>
                    <FiShare2 /> Share
                  </a>
                </div>
              </div>

              {/* related posts  */}
              <div className='related_posts_wrapper'>
                <Link to='/blog-3' className='related_post_item'>
                  <img src={postThumb4} alt=' nft post' />
                  <div className='related_post__title'>
                    <span>PREVIOUS</span>
                    How to Create Your 1st Crypto NFTs 🎉
                  </div>
                </Link>
                <Link to='/blog-5' className='related_post_item next'>
                  <img src={postThumb5} alt=' nft post' />

                  <div className='related_post__title'>
                    <span>Next</span>
                    The new token is launching planet
                  </div>
                </Link>
              </div>

              {/* <div className='post_comments_wrapper'>
                <h4 className='comment_section_title'>3 Comments</h4>
                <Comment />
              </div>

              <CommentBoxment /> */}
            </div>

            {/* **********sidebar*********** */}
            <div className='col-lg-4 col-md-12'>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  )
}

export default BlogDetails4
