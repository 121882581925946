import { useModal } from "../utils/ModalContext"
import GlobalStyles from "../assets/styles/GlobalStyles"
import Layout from "../common/layout"
import Header from "../components/section/header/v1/Header"
import PageHeader from "../common/pageHeader/v1"
import CTA from "../components/section/cta/v2"
import Footer from "../components/section/footer/v3"
import ShareModal from "../common/modal/shareModal/ShareModal"
import WalletModal from "../common/modal/walletModal/WalletModal"
import BlogDetails4 from "../components/section/blog/blogDetails/BlogDetails4"
const Blog4 = () => {
  const { shareModalVisibility, walletModalvisibility } = useModal()
  return (
    <>
      <Layout>
        <GlobalStyles />
        {shareModalVisibility && <ShareModal />}
        {walletModalvisibility && <WalletModal />}
        <Header />
        <PageHeader />
        <BlogDetails4 />
        <CTA />
        <Footer />
      </Layout>
    </>
  )
}

export default Blog4
