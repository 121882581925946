import { useState } from "react"
import { ModalContext } from "./ModalContext"
import {
  connectWallet,
  connectWalletLocaly,
  isWalletConnected,
  disconnectWallet,
} from "../config"

const ContextProvider = ({ children }) => {
  const [visibility, setVisibility] = useState(false)
  const [walletModalvisibility, setModalvisibility] = useState(false)
  const [shareModalVisibility, setShareModalvisibility] = useState(false)
  const [metamaskModalVisibility, setMetamaskModalVisibility] = useState(false)
  const [connectWalletModal, setConnectWalletModal] = useState(false)
  const [account, setAccount] = useState("")
  const [askModalVisibility, setAskModalVisibility] = useState(false)

  const askModalHandle = () => {
    setAskModalVisibility(!askModalVisibility)
  }
  const mintModalHandle = () => {
    setVisibility(!visibility)
  }
  const walletModalHandle = () => {
    setModalvisibility(!walletModalvisibility)
  }
  const shareModalHandle = (e) => {
    e.preventDefault()
    setShareModalvisibility(!shareModalVisibility)
  }

  const metamaskModalHandle = () => {
    setMetamaskModalVisibility(!metamaskModalVisibility)
  }

  const connectWalletModalHanlde = () => {
    if (!isWalletConnected()) {
      setConnectWalletModal(!connectWalletModal)
    }
  }

  const connectWalletHandle = async () => {
    const accounts = await connectWallet()
    setAccount(accounts)
    if (!isWalletConnected()) {
      connectWalletLocaly()
    }
    setModalvisibility(!walletModalvisibility)
  }

  const isWalletAlreadyConnected = async () => {
    if (isWalletConnected()) {
      const accounts = await connectWallet()
      setAccount(accounts)
    }
  }

  const disconnectWalletFromApp = () => {
    disconnectWallet()
    setAccount("")
  }

  return (
    <ModalContext.Provider
      value={{
        visibility,
        mintModalHandle,
        walletModalHandle,
        walletModalvisibility,
        shareModalVisibility,
        shareModalHandle,
        metamaskModalVisibility,
        metamaskModalHandle,
        account,
        connectWalletHandle,
        isWalletAlreadyConnected,
        disconnectWalletFromApp,
        connectWalletModalHanlde,
        connectWalletModal,
        askModalHandle,
        askModalVisibility,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ContextProvider
