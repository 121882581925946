import abi from "./Transactions.json"

// export const contractAddress = "0x125b984754962e33Dc56dAf4977757553e09E280" 0x932205a12cB6ceC252431E0c40944419fDc2d455
export const contractAddress = "0x0FF509114fdf67373A6E24843f31A2FaD73Ae082"

export const contractABI = abi.abi

// export const originalChainID = 80001
export const originalChainID = 1


// export const blockURL = "https://mumbai.polygonscan.com/tx/"

export const blockURL = "https://etherscan.io/tx/"

export const WhiteListAddress = [
    "0x501A0876bC5489da3B88c19279A355762b8BEe35",
    "0xc6f393a7e8A7ca6B08847f54C2605628c8826000",
    "0x1F168c85a16f78FFE1D369255cd5aa15AA495a26",
    "0x814BF6f6c2120dD1Bcc1BF439c81572F321A9042",
    "0x034490f396cb3BbFf9864DC9E5f3e69aC8a3677C",
    "0xe9137F77b9fB78E3F4D21d9674dACC757a65300E",
    "0x420e0C5Bf8b296Ef106E2025bb95d8e2342cAeDD",
    "0xa5A85aFA40ff40674115dB1032600cD2699475D2",
    "0xEd70A2f9285CfB745b126f598d1a8b34D38479B3",
    "0xB21E33663e00F9eEC55e41D4C175f0a15cf8f70C",
    "0x75c0d794E7A80F3A61B6ad106a5Be48411AFba24",
    "0x3938c8FC2033eb9A6B86b1893f16419d82Bc6af8",
    "0x83ef7632c7A7bcD335c464c058c1493B5267C566",
    "0x112dAe49e28131639ba145f93c83f03C7acd4609",
    "0x6D63f7d55cBfeaf4aebAA2313Bffda2221381461",
    "0xD9Dca533753055F4305595d7cC4FB25d91E5C036",
    "0x5E9EDD177AcbB795cD059584aD2F860177A4D5C8",
    "0x952aF3320A2942F54C30A0961AF6Ec17bC9A123A",
    "0x6D63f7d55cBfeaf4aebAA2313Bffda2221381461",
    "0x36B3A380e9c3Cf18154E4C99d967CCD056F3E4E7",
    "0x0cB1fc31037C8496Cf58a9F5B8392B9D19cF196f",
    "0x28C6E73ca4A8e1F7969202Fb59e5ec4698eE4188",
    "0x0744177379EBD70800c420706a5129dd82632799",
    "0x151a7b2C6afEc828e687a8544527Dd03bDb78CDC",
    "0xc36e4147CD21c94C13415f90ca6d03258e542f36",
    "0x3BDF8C4F5Fd8E40CAcb8DFDa2B9cA5049DF1610B",
    "0xab121D9b0f2F5550D632118796b38E9B3247783f",
    "0x2C76099e5f2E5bd37B1306b5CB986419d7bB8E4c",
    "0xdC9162E30173b503c745F5C0515e2DE415cC9dB0",
    "0x9300a750cdB2100A21900a6c62d5e1B9df59f99B",
    "0x6e6a82a7BbeA0DFb6D205F181Aa82dC01FBFE5E7",
    "0x15ae364a321177472Fb4230bC4fE0d1915b636cf",
    "0xe65e9D7726Cc03A8120A1196E848Ad789616DC5A",
    "0xdA63a35eD5Fd3887b834d19a16134b8CE601490E",
    "0x2CA3624830664b73044b30CD6539354665279236",
    "0x5F60d4769a165B56527e5dA5a4613118a56e7C7b",
    "0x170Ca109A1F2Ab94e62B1a723CA38Ed160984c15",
    "0xc36e4147CD21c94C13415f90ca6d03258e542f36",
    "0xe39c61aBe04Dac102854ABF3Ce6c20eFD043e9aa",
    "0x6AAb804dfcf4bC34727a50978A51F3140BaC62D2",
    "0xF05eDf82761FeE53Fd7aD527addf002b1C143D50",
    "0x21BDDF8D26Fe90B834d6432FeFDc3eD49Aa1b119",
    "0xFb268D948d1EFb445ca0737477Db2818c9E522B2",
    "0xBe2B33b99dC4F910605fbcB1b999fD1A3c5E9657",
    "0xe7b4dB5a21DcB2d32B25C746b5DA6995b7617FCc",
    "0x4047e4132904f2010360118b95FCf3A6D43f4E38",
    "0xc3fdd364Bd74F7D23a7C7260dbb1F2B87BD7612E",
    "0xB5b277d46227F5416c6239d9A633D68006F4D792",
    "0x8Ebf75a9f097b0D7E54b8E3c9f9ace0cD4B2f3C9",
    "0x87D827a433c479991fe45A22776fF5342C89aDC0",
    "0x0D52F58E68671F927d89De7BC669954bE7793d14",
    "0xc2E62df636bFEE32230BE6f1C24c096a6e6feF05",
    "0x97903CBD477602E073Ded313750088e5eF451E42",
    "0xe16012a7c1eeb3ef1cbef5c9f1b9535789406253",
    "0x85375A9c8bde4DF00240F76873b0da26292CadfE",
    "0x1e6dEfA90d2e1826B70a1f047578533F2e62653e",
    "0x7633aE6594Cc67D426Fa3b329F41C9de018C6D8d",
    "0x394cafC236E1B076B121ee94E0f38AE8f8CBF9D4",
    "0x291048EDC8d2AFbe07c91467bf45C4846567761E",
    "0x04ccb851025Ed8450fDca3793693506A46F1f150",
    "0x26D8a06e0FA2D1296C8ff4ac2d7f50dA732aE4C7",
    "0xE4B2ed783AD4fF0a86275D328FCa64eE8dD3C52A",
    "0x3d6307eb0e523f36a83e39f48b23d96678ba81d9",
    "0xB23d6221a8C5d39D3Bd6af2499055659F15A2aa5",
    "0x678991F4176bfa4CA81d2FeB9f7bedd511bd847D",
    "0x21F3278a52D05db1c9EC07A32e64362B335c9A84",
    "0xccB1Ac0837464FF566Ffc327f1af235c449F9658",
    "0xf0aa8D4958626B8755E553A00bC7aA72Dac29eC7",
    "0x6D9DD8337f5CA499E89654b41a5CA45A2eA06F63",
    "0x8689bbA305dc32F9B0f8ba661Fa48f55d010A4aC",
    "0xD9Dca533753055F4305595d7cC4FB25d91E5C036",
    ""
];
