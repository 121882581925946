import SectionTitle from "../../../../common/sectionTitle"

// import data from "../../../../assets/data/about/dataV2"

import AboutStyleWrapper from "./About.style"

const About = () => {
  // const { aboutDescription1, aboutDescription2, artists } = data;
  return (
    <AboutStyleWrapper id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='v1_about_us_right_sect'>
              <SectionTitle title='ABOUT US' subtitle='THE STORY ' />
              <div className='v1_about_us_right_text'>
                {/* <p>{aboutDescription1}</p>
                <p>{aboutDescription2}</p> */}
                <p>
                  Welcome to our fascinating world of Alien NFTs! We are a
                  passionate team of artists, creators, and collectors who share
                  a deep love for the unknown and extraterrestrial.
                </p>
                <p>
                  Our mission is to bring these enigmatic beings to life through
                  stunning digital art that sparks your imagination and
                  curiosity. Each Alien NFT is carefully crafted with meticulous
                  attention to detail, making them truly one-of-a-kind treasures
                  in the vast NFT galaxy.
                </p>
                <p>
                  At our core, we believe in inclusivity and unity, bringing
                  together a diverse community of enthusiasts who share the same
                  awe and fascination for the mysteries of the universe. We take
                  pride in offering an authentic and enjoyable experience, from
                  the creation of our NFTs to fostering a vibrant and supportive
                  community.
                </p>
                <p>
                  Join us on this interstellar journey as we explore new
                  frontiers, unravel cosmic secrets, and celebrate the boundless
                  creativity of our Alien NFTs. Together, we embark on an
                  extraordinary adventure through time and space, leaving no
                  corner of the universe unexplored.
                </p>
                <h4 style={{ color: "white" }}>Mission</h4>
                <p>
                  To ignite the imagination of the world through captivating
                  Alien NFTs while building a close-knit community of
                  like-minded individuals united in the pursuit of artistic
                  exploration.
                </p>
                <h4 style={{ color: "white" }}>Vision</h4>
                <p>
                  To become a beacon of inspiration in the NFT space, fostering
                  a community of alien enthusiasts who come together to
                  celebrate art, creativity, and the enigmatic beauty of the
                  cosmos.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="about-left-item">
              <SectionTitle
                subtitle="Meet The Artists"
                className="about_section_title"
              />
              <div className="ab-content">
                {artists?.map((item, i) => (
                  <div key={i} className="ab-item-box mr-30">
                    <img
                      className="author-thumbnail"
                      src={item.thumb}
                      alt=" nft about"
                    />
                    <div className="ab-text pt-20">
                      <h5>
                        {item.name}
                        <img src={item.icon} alt=" nft about icon" />
                      </h5>
                    </div>
                    <span>{item.role}</span>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </AboutStyleWrapper>
  )
}

export default About
