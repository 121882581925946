import { Routes, Route, Navigate } from "react-router-dom"
import HomeV1 from "../pages/homeV1"
import HomeV2 from "../pages/homeV2"
import HomeV3 from "../pages/homeV3"
import HomeV4 from "../pages/homeV4"
import HomeV5 from "../pages/homeV5"
import Blogs from "../pages/blogs"
import BlogDetails from "../pages/blogDetails"
import ComingSoon from "../pages/comingSoon"
import AboutPage from "../pages/about"
import RoadmapPage from "../pages/roadmap"
import Collections from "../pages/collections"
import ContactPage from "../pages/contact"
import MintPageOne from "../pages/mint-1"
import MintPageTwo from "../pages/mint-2"
import FAQPage from "../pages/faq"
import Blog1 from "../pages/blog-1"
import Blog2 from "../pages/blog-2"
import Blog3 from "../pages/blog-3"
import Blog4 from "../pages/blog-4"
import Blog5 from "../pages/blog-5"
import Blog6 from "../pages/blog-6"
import Blog7 from "../pages/blog-7"
import Blog8 from "../pages/blog-8"

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomeV3 />} exact />
      <Route path='*' element={<Navigate to='/' />} />
      <Route path='/home-one' element={<HomeV1 />} />
      <Route path='/home-two' element={<HomeV2 />} />
      <Route path='/home-three' element={<HomeV3 />} />
      <Route path='/home-four' element={<HomeV4 />} />
      <Route path='/home-five' element={<HomeV5 />} />
      <Route path='/blogs' element={<Blogs />} />
      <Route path='/post' element={<BlogDetails />} />
      <Route path='/coming-soon' element={<ComingSoon />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/faq' element={<FAQPage />} />
      <Route path='/roadmap' element={<RoadmapPage />} />
      <Route path='/collections' element={<Collections />} />
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/mint-1' element={<MintPageOne />} />
      <Route path='/mint-2' element={<MintPageTwo />} />
      <Route path='/blog-1' element={<Blog1 />} />
      <Route path='/blog-2' element={<Blog2 />} />
      <Route path='/blog-3' element={<Blog3 />} />
      <Route path='/blog-4' element={<Blog4 />} />
      <Route path='/blog-5' element={<Blog5 />} />
      <Route path='/blog-6' element={<Blog6 />} />
      <Route path='/blog-7' element={<Blog7 />} />
      <Route path='/blog-8' element={<Blog8 />} />
    </Routes>
  )
}

export default App
