import { useModal } from "../../../../utils/ModalContext"
import { FiShare2 } from "react-icons/fi"
// import { FaCalendarAlt } from "react-icons/fa"
import BlogDetailsStyleWrapper from "./BlogDestails.style"
// components
import Tag from "../tag/Tag"
// import Comment from "../comment/Comment"
// import CommentBoxment from "../commentBox/CommentBox"
import Sidebar from "../sidebar/Sidebar"

//images
import postThumb from "../../../../assets/images/blog/details/8.png"
// import postThumb2 from "../../../../assets/images/blog/blog-img2.png"
// import postThumb3 from "../../../../assets/images/blog/blog-img3.png"
// import avatarIcon from "../../../../assets/images/blog/user.png"
import postThumb4 from "../../../../assets/images/blog/blog.png"
import postThumb5 from "../../../../assets/images/blog/blog2.png"
import { Link } from "react-router-dom"

const BlogDetails8 = () => {
  const { shareModalHandle } = useModal()
  return (
    <BlogDetailsStyleWrapper>
      <div className='blog_post_details_wrapper'>
        <div className='container'>
          <div className='row'>
            {/* post details col  */}
            <div className='col-lg-8 col-md-12'>
              <div className='post_thumbnail'>
                <img src={postThumb} alt=' nft blog' />
              </div>
              {/* <div className='blog_post_meta'>
                <a href='# ' className='post_author'>
                  <img src={avatarIcon} alt='blog post avatar' />
                  Inna Mouaz
                </a>
                <span>
                  {" "}
                  <FaCalendarAlt /> 18 FEB, 2022
                </span>
              </div> */}
              <h1 className='post_title'>The legal implications of NFTs</h1>
              <div className='blog_description'>
                <p>
                  The legal implications of NFTs are still being debated. Some
                  people argue that NFTs are simply digital assets, and that
                  they are not subject to any specific laws. Others argue that
                  NFTs could be considered securities, and that they would be
                  subject to securities laws.
                </p>

                <p>
                  The legal status of NFTs will likely depend on how they are
                  used. If NFTs are used to represent real-world assets, such as
                  property or art, then they could be considered securities.
                  However, if NFTs are used for more creative purposes, such as
                  representing artwork or music, then they may not be subject to
                  any specific laws.
                </p>
                <h3>
                  Here are some of the legal issues that could arise from NFTs:
                </h3>
                <h4>Securities laws</h4>
                <p>
                  NFTs could be considered securities if they are used to
                  represent investments. This could mean that NFTs would be
                  subject to securities laws, such as the Securities Act of 1933
                  and the Securities Exchange Act of 1934.
                </p>
                <h4>Securities laws</h4>
                <p>
                  Use NFTs for applications that have a lower environmental
                  impact, such as music tracks or albums. Offset the
                  environmental impact of NFTs by planting trees or investing in
                  renewable energy projects.
                </p>
                <h4>Copyright law</h4>
                <p>
                  NFTs could be subject to copyright law if they are used to
                  represent copyrighted works. This could mean that NFT owners
                  could be liable for copyright infringement if they sell or
                  distribute NFTs that contain copyrighted material.
                </p>
                <h4>Tax law</h4>
                <p>
                  NFTs could be subject to tax law if they are used to represent
                  assets. This could mean that NFT owners could be liable for
                  taxes on the sale or transfer of NFTs.
                </p>
                <p>
                  The legal implications of NFTs are still being debated. It is
                  important to be aware of the potential legal risks before you
                  invest in or create NFTs.
                </p>

                <p>
                  I hope this blog post has given you some insights into the
                  legal implications of NFTs. If you have any questions, please
                  feel free to leave a comment below.
                </p>
                <p>Is there anything else that I can help you with today?</p>
              </div>
              {/* // tags  */}
              <div className='post_tags_list'>
                <Tag />
                <div className='share_butn'>
                  <a href='# ' onClick={(e) => shareModalHandle(e)}>
                    <FiShare2 /> Share
                  </a>
                </div>
              </div>

              {/* related posts  */}
              <div className='related_posts_wrapper'>
                <Link to='/blog-7' className='related_post_item'>
                  <img src={postThumb4} alt=' nft post' />
                  <div className='related_post__title'>
                    <span>PREVIOUS</span>
                    How to Create Your 1st Crypto NFTs 🎉
                  </div>
                </Link>
                <Link to='/blog-1' className='related_post_item next'>
                  <img src={postThumb5} alt=' nft post' />

                  <div className='related_post__title'>
                    <span>Next</span>
                    The new token is launching planet
                  </div>
                </Link>
              </div>

              {/* <div className='post_comments_wrapper'>
                <h4 className='comment_section_title'>3 Comments</h4>
                <Comment />
              </div>

              <CommentBoxment /> */}
            </div>

            {/* **********sidebar*********** */}
            <div className='col-lg-4 col-md-12'>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  )
}

export default BlogDetails8
