// import characterThumb1 from "../../images/nft/carousel_avater1.png";
// import characterThumb2 from "../../images/nft/carousel_avater2.png";
// import characterThumb3 from "../../images/nft/carousel_avater3.png";
// import characterThumb4 from "../../images/nft/carousel_avater4.png";
// import characterThumb5 from "../../images/nft/carousel_avater5.png";
// import characterThumb6 from "../../images/nft/carousel_avater6.png";
import characterThumb1 from "../../images/AP/IMG_5214.JPG"
import characterThumb2 from "../../images/AP/IMG_5218.JPG"
import characterThumb3 from "../../images/AP/IMG_5219.JPG"
import characterThumb4 from "../../images/AP/IMG_5220.JPG"
import characterThumb5 from "../../images/AP/IMG_5221.JPG"
import characterThumb6 from "../../images/AP/IMG_5222.JPG"

const data = [
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb2,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb4,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb5,
  },
]

export default data
