import React, { useEffect, useState } from "react"
import { ethers, BigNumber } from "ethers"
import {
  contractABI,
  contractAddress,
  originalChainID,
  WhiteListAddress
} from "../utils/constants"

export const TransactionContext = React.createContext()

const { ethereum } = window

const createEthereumContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const transactionsContract = new ethers.Contract(
    contractAddress,
    contractABI,
    signer
  )

  return transactionsContract
}

export const TransactionsProvider = ({ children }) => {
  const [formData, setformData] = useState({
    addressTo: "",
    amount: "",
    keyword: "",
    message: "",
  })
  const [errorMsg, setErrorMsg] = useState("")
  const [successResponse1, setSuccessResponse1] = useState(null)
  const [loader, setLoader] = useState(false)
  const [currentAccount, setCurrentAccount] = useState(
    localStorage.getItem("metamaskAccount")
  )
  const [isLoading, setIsLoading] = useState(false)
  const [transactionCount, setTransactionCount] = useState(
    localStorage.getItem("transactionCount")
  )
  const [transactions, setTransactions] = useState([])

  const handleChange = (e, name) => {
    setformData((prevState) => ({ ...prevState, [name]: e.target.value }))
  }

  //   const getAllTransactions = async () => {
  //     try {
  //       if (ethereum) {
  //         const transactionsContract = createEthereumContract()

  //         const availableTransactions =
  //           await transactionsContract.getAllTransactions()

  //         const structuredTransactions = availableTransactions.map(
  //           (transaction) => ({
  //             addressTo: transaction.receiver,
  //             addressFrom: transaction.sender,
  //             timestamp: new Date(
  //               transaction.timestamp.toNumber() * 1000
  //             ).toLocaleString(),
  //             message: transaction.message,
  //             keyword: transaction.keyword,
  //             amount: parseInt(transaction.amount._hex) / 10 ** 18,
  //           })
  //         )

  //         console.log(structuredTransactions)

  //         setTransactions(structuredTransactions)
  //       } else {
  //         console.log("Ethereum is not present")
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }

  const checkIfWalletIsConnect = async () => {
    try {
      setLoader(true)
      if (!ethereum) {
        setLoader(false);
        console.log("Please install MetaMask.");
        return;
        // return alert("Please install MetaMask.")
      }

      const accounts = await ethereum.request({ method: "eth_accounts" })

      if (accounts.length) {
        setCurrentAccount(accounts[0])
        // console.log(accounts[0], typeof accounts[0] === "string");
        localStorage.setItem("metamaskAccount", accounts[0])
        const chainId = await getChainId()
        console.log(chainId, "chain Id")
        if (chainId !== originalChainID) {
          setErrorMsg("Please Connect to the Ethereum Main net")
        } else {
          setErrorMsg("")
        }
        setLoader(false)
        return true
      } else {
        console.log("No accounts found")
        localStorage.setItem("metamaskAccount", "")
        setLoader(false)
        return false
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  //   const checkIfTransactionsExists = async () => {
  //     try {
  //       if (ethereum) {
  //         const transactionsContract = createEthereumContract()
  //         const currentTransactionCount =
  //           await transactionsContract.getTransactionCount()

  //         window.localStorage.setItem("transactionCount", currentTransactionCount)
  //       }
  //     } catch (error) {
  //       console.log(error)

  //       throw new Error("No ethereum object")
  //     }
  //   }

  const connectWallet = async () => {
    try {
      setLoader(true)
      if (!ethereum) {
        setLoader(false);
        console.log("Please install MetaMask.");
        return;
        // return alert("Please install MetaMask.")
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" })
      localStorage.setItem("metamaskAccount", accounts[0])
      setCurrentAccount(accounts[0])
      setErrorMsg("")
      const chainId = await getChainId()
      console.log(chainId, "chain Id")
      if (chainId !== originalChainID) {
        setErrorMsg("Please Connect to the Ethereum Main net")
      } else {
        setErrorMsg("")
      }
      if (accounts[0]) {
        console.log("Connected!")
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      // console.log(error, "dasdasds")
      if (error.code === -32002) {
        setErrorMsg("MetaMask popUp Already Opened")
      } else {
        setErrorMsg("Something Went Wrong")
      }
      //   throw new Error("No ethereum object")
    }
  }

  const handelMint = async (count) => {
    if (count < 1) {
      return setErrorMsg("Mint cannot be less than 1 NFT")
    }
    try {
      setErrorMsg("")
      setSuccessResponse1(null)
      const check = await checkIfWalletIsConnect()
      if (check) {
        const chainId = await getChainId()
        if (chainId !== originalChainID) {
          return setErrorMsg("Please Connect to the Ethereum Main net")
        }
        setLoader(true)
        if (ethereum) {
          const transactionsContract = createEthereumContract();
          let mintCost = 0.0059;
          let totalCost = mintCost * Math.ceil(count);
          // if(!WhiteListAddress.includes(currentAccount.toString())) {
          //   return setErrorMsg("Please wait! Our public mint will start on 14th Nov, 2023")
          // }
          if (WhiteListAddress.includes(currentAccount.toString())) {
            mintCost = 0.0050;
            totalCost = mintCost * Math.ceil(count)
          }
          else if (Math.ceil(count) > 3) {
            totalCost = totalCost - ((totalCost * 10) / 100);
          }
          // const mintCost = WhiteListAddress.includes(currentAccount) ? 0.072 : 0.09;
          // const totalCost = mintCost * Math.ceil(count)
          const parsedAmount = ethers.utils.parseEther(totalCost.toString())
          try {
            const response = await transactionsContract.mintAPNFT(
              BigNumber.from(1),
              {
                value: parsedAmount,
              }
            )
            await response.wait()
            // console.log(response)
            setSuccessResponse1(response)
            setErrorMsg("")
          } catch (error) {
            // console.log(
            //   "error",
            //   JSON.parse(JSON.stringify(error)).code,
            //   JSON.parse(JSON.stringify(error))
            // )
            if (
              JSON.parse(JSON.stringify(error)).code === "INSUFFICIENT_FUNDS"
            ) {
              setErrorMsg("Insufficient funds for Minting!")
            } else if (
              JSON.parse(JSON.stringify(error)).code ===
              "UNPREDICTABLE_GAS_LIMIT"
            ) {
              setErrorMsg("User is not whitelisted!")
            } else if (
              JSON.parse(JSON.stringify(error)).code === "ACTION_REJECTED"
            ) {
              setErrorMsg("You have cancelled the minting!")
            } else if (JSON.parse(JSON.stringify(error)).code === -32603) {
              setErrorMsg("Insufficient funds for Minting!")
            } else {
              setErrorMsg("You are not authorized for Minting!")
            }
          }
        } else {
          console.log("No ethereum object")
        }
        setLoader(false)
      } else {
        setErrorMsg("Please Connect Any Wallet First")
      }
    } catch (error) {
      setLoader(false)
      setErrorMsg("You are not authorized!")
    }
  }

  useEffect(() => {
    checkIfWalletIsConnect()
    // checkIfTransactionsExists()
  }, [transactionCount])
  const disConnectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      window.ethereum
        .request({
          method: "eth_requestAccounts",
          params: [{}],
        })
        .then((accounts) => {
          if (accounts.length === 0) {
            localStorage.setItem("metamaskAccount", "")

            // The user has been logged out
            console.log("Logged out of MetaMask")
          } else {
            // Something went wrong or the user refused to log out
            console.log("Logout request denied or failed")
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error(error)
        })
    } else {
      console.log("MetaMask is not installed or not available.")
    }
  }

  const getChainId = async () => {
    const chainId = await ethereum.request({ method: "eth_chainId" })
    return parseInt(chainId)
  }

  return (
    <TransactionContext.Provider
      value={{
        transactionCount,
        connectWallet,
        transactions,
        currentAccount,
        isLoading,
        handelMint,
        handleChange,
        formData,
        errorMsg,
        setErrorMsg,
        disConnectWallet,
        getChainId,
        loader,
        setLoader,
        successResponse1,
        setSuccessResponse1,
      }}
    >
      {children}
    </TransactionContext.Provider>
  )
}
