import { useModal } from "../../../../utils/ModalContext"
import { FiShare2 } from "react-icons/fi"
// import { FaCalendarAlt } from "react-icons/fa"
import BlogDetailsStyleWrapper from "./BlogDestails.style"
// components
import Tag from "../tag/Tag"
// import Comment from "../comment/Comment"
// import CommentBoxment from "../commentBox/CommentBox"
import Sidebar from "../sidebar/Sidebar"

//images
import postThumb from "../../../../assets/images/blog/details/5.png"
// import postThumb2 from "../../../../assets/images/blog/blog-img2.png"
// import postThumb3 from "../../../../assets/images/blog/blog-img3.png"
// import avatarIcon from "../../../../assets/images/blog/user.png"
import postThumb4 from "../../../../assets/images/blog/blog.png"
import postThumb5 from "../../../../assets/images/blog/blog2.png"
import { Link } from "react-router-dom"

const BlogDetails5 = () => {
  const { shareModalHandle } = useModal()
  return (
    <BlogDetailsStyleWrapper>
      <div className='blog_post_details_wrapper'>
        <div className='container'>
          <div className='row'>
            {/* post details col  */}
            <div className='col-lg-8 col-md-12'>
              <div className='post_thumbnail'>
                <img src={postThumb} alt=' nft blog' />
              </div>
              {/* <div className='blog_post_meta'>
                <a href='# ' className='post_author'>
                  <img src={avatarIcon} alt='blog post avatar' />
                  Inna Mouaz
                </a>
                <span>
                  {" "}
                  <FaCalendarAlt /> 18 FEB, 2022
                </span>
              </div> */}
              <h1 className='post_title'>How to create your own NFT</h1>
              <div className='blog_description'>
                <h3>
                  If you are interested in creating your own NFT, there are a
                  few things you will need to do:
                </h3>
                <h4>Choose a blockchain:</h4>
                <p>
                  The first step is to choose a blockchain on which you want to
                  create your NFT. Some popular blockchains for NFTs include
                  Ethereum, Solana, and Tezos. Create a digital asset: The next
                  step is to create a digital asset that you want to mint into
                  an NFT. This could be an image, a video, a piece of music, or
                  anything else that can be stored digitally.
                </p>
                <h4>Mint your NFT:</h4>
                <p>
                  Once you have created your digital asset, you need to mint it
                  into an NFT. This process involves uploading your digital
                  asset to a blockchain and creating a unique token for it.
                </p>
                <h4>List your NFT for sale:</h4>
                <p>
                  Once your NFT is minted, you can list it for sale on a
                  marketplace. There are a number of NFT marketplaces available,
                  such as OpenSea, Rarible, and Foundation.
                </p>
                <h3>
                  Here are some additional tips for creating your own NFT:
                </h3>
                <h4>Make sure your digital asset is high-quality:</h4>
                <p>
                  The quality of your digital asset will have a big impact on
                  the value of your NFT. Make sure your asset is well-made and
                  visually appealing.
                </p>
                <h4>Choose a unique name and description:</h4>
                <p>
                  Your NFT's name and description should be unique and
                  attention-grabbing. This will help people to find your NFT and
                  understand what it is.
                </p>
                <h4>Set a fair price:</h4>
                <p>
                  When you are listing your NFT for sale, set a fair price. You
                  don't want to price your NFT too high, or no one will buy it.
                  But you also don't want to price it too low, or you will be
                  losing out on potential profits.
                </p>
                <p>
                  I hope this blog post has helped you to understand what NFTs
                  are and how they work. If you have any questions, please feel
                  free to leave a comment below.
                </p>
              </div>
              {/* // tags  */}
              <div className='post_tags_list'>
                <Tag />
                <div className='share_butn'>
                  <a href='# ' onClick={(e) => shareModalHandle(e)}>
                    <FiShare2 /> Share
                  </a>
                </div>
              </div>

              {/* related posts  */}
              <div className='related_posts_wrapper'>
                <Link to='/blog-4' className='related_post_item'>
                  <img src={postThumb4} alt=' nft post' />
                  <div className='related_post__title'>
                    <span>PREVIOUS</span>
                    How to Create Your 1st Crypto NFTs 🎉
                  </div>
                </Link>
                <Link to='/blog-6' className='related_post_item next'>
                  <img src={postThumb5} alt=' nft post' />

                  <div className='related_post__title'>
                    <span>Next</span>
                    The new token is launching planet
                  </div>
                </Link>
              </div>

              {/* <div className='post_comments_wrapper'>
                <h4 className='comment_section_title'>3 Comments</h4>
                <Comment />
              </div>

              <CommentBoxment /> */}
            </div>

            {/* **********sidebar*********** */}
            <div className='col-lg-4 col-md-12'>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  )
}

export default BlogDetails5
