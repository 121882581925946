import Particle from "../../../../common/particle/v2"
import CountdownTimer from "react-component-countdown-timer"
import { useContext, useState } from "react"
import bannerThumb1 from "../../../../assets/images/banner/Item11.png"
import bannerThumb2 from "../../../../assets/images/banner/Item12.png"
import bannerThumb3 from "../../../../assets/images/banner/Item13.png"
import nftcalendar from "../../../../assets/images/nftcalendar-svg-white.svg"
import Button from "../../../../common/button"
import BannerStyleWrapper from "./Banner.style"
import { TransactionContext } from "../../../../context/transactionContext"
import { useEffect } from "react"
import { blockURL } from "../../../../utils/constants"
const Banner = () => {
  const { handelMint, errorMsg, successResponse1 } =
    useContext(TransactionContext)

  const [count, setCount] = useState(1)

  const targetDate = new Date("November 15, 2023 15:30:00").getTime()

  const calculateRemainingTime = () => {
    const now = new Date().getTime()
    const difference = targetDate - now

    if (difference <= 0) {
      // Countdown has ended
      return 0
    }
    console.log(difference / 1000)
    return difference / 1000 // Convert to seconds
  }
  const counterSettings = {
    count: calculateRemainingTime(),
    showTitle: true,
    size: 40,
    labelSize: 24,
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "D",
    hourTitle: "H",
    minuteTitle: "M",
    secondTitle: "S",
    id: "countdownwrap",
  }
  useEffect(() => {
    console.log(successResponse1, "dasd")
  }, [successResponse1])

  const blockURLSend = () => {
    const url = `${blockURL}${successResponse1?.hash}`
    window.open(url, "_blank")
  }
  return (
    <BannerStyleWrapper className='bithu_v3_baner_sect' id='home'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='banner-image-area3'>
              {/* particles component */}
              <Particle />
              <img
                className='banner-image banner-image1'
                src={bannerThumb1}
                alt='bithu banner thumb'
              />
              <img
                className='banner-image banner-image2'
                src={bannerThumb2}
                style={{ zIndex: "4" }}
                alt='bithu banner thumb'
              />
              <img
                className='banner-image banner-image3'
                src={bannerThumb3}
                alt='bithu banner thumb'
              />
            </div>
          </div>
          <div className='col-lg-6'>
            <h4
              className='banner-title text-uppercase text-white'
              style={{
                marginBottom: ".5rem",
                textTransform: "capitalize !important",
              }}
            >
              Public Minting Started
            </h4>
            <ul style={{ color: "white", marginBottom: "1rem" }}>
              <li>Connect to Your Wallet for Minting</li>
            </ul>
            <div className='banner-count-inner d-flex align-items-center'>
              <div className='banner-btn-area'>
                <span
                  className='input-number-decrement'
                  onClick={() => (count > 1 ? setCount(count - 1) : count)}
                >
                  –
                </span>
                <input
                  className='input-number'
                  type='text'
                  disabled
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                />
                <span
                  className='input-number-increment'
                  onClick={() => setCount(count + 1)}
                >
                  +
                </span>
              </div>
              <div className='bithu_v3_baner_buttons'>
                <Button lg variant='mint' onClick={() => handelMint(count)}>
                  {" "}
                  Mint now
                </Button>
              </div>
            </div>
            {successResponse1?.hash && (
              <div>
                <h4
                  className='banner-title text-uppercase text-white'
                  style={{
                    marginTop: "2rem",
                    marginBottom: ".5rem",
                    textTransform: "capitalize !important",
                  }}
                >
                  Your minting is successful
                </h4>{" "}
                <p>
                  You can check the transaction &nbsp;
                  <span
                    style={{
                      color: "#00ffa3",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={blockURLSend}
                  >
                    Here!
                  </span>
                </p>
              </div>
            )}
            {/* <h4
              className='banner-title text-uppercase text-white'
              style={{
                marginTop: "2rem",
                marginBottom: ".5rem",
                textTransform: "capitalize !important",
              }}
            >
              Mint will start in
            </h4>
            <CountdownTimer {...counterSettings} /> */}
            <div className='col-12 mt-3'>
            <a target="_blank" rel="noreferrer" href="https://nftcalendar.io ">
              <img alt="nft" height="100" width="100" src={nftcalendar}></img>
            </a>
          </div>
          </div>
        </div>
      </div>
    </BannerStyleWrapper>
  )
}

export default Banner
