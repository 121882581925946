const data = [
  {
    widgetName: "Site map",
    items: [
      {
        title: "Home",
        url: "/",
      },
      {
        title: "About",
        url: "/about",
      },
      {
        title: "Roadmap",
        url: "/roadmap",
      },
      {
        title: "FAQ",
        url: "/faq",
      },
      {
        title: "Blogs",
        url: "/blogs",
      },
    ],
  },
  // {
  //   widgetName: "Site map",
  //   items: [
  //     {
  //       title: "Whitepaper",
  //       url: "#",
  //     },
  //     {
  //       title: "Terms Canditions",
  //       url: "#",
  //     },
  //     {
  //       title: "Privacy Policy",
  //       url: "#",
  //     },
  //   ],
  // },
]

export default data
