import React from "react"
import DotLoader from "react-spinners/DotLoader"

function OverlaySpinner() {
  const color = "green"
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#000000c4",
  }
  return (
    <div
      style={{
        padding: "0px",
        backgroundColor: "#000000c7",
        height: "100%",
        width: "100%",
        top: "0",
        position: "fixed",
        left: "0",
        zIndex: "1201",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <DotLoader color={color} loading css={override} size={80} />
      </div>
    </div>
  )
}

export default OverlaySpinner
