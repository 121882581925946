import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  // FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa"

import openseaIcon from "../images/icon/opensea.svg"
// import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    thumb: openseaIcon,
    url: "http://www.alienplanetarium.com/",
  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/NftAlienPlanet?t=TlwjGAYE6RPKi17r0OZyeQ&s=03",
  },
  {
    icon: <FaLinkedinIn />,
    url: "#",
  },
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/profile.php?id=100092994216194&mibextid=LQQJ4d",
  },
  {
    icon: <FaInstagram />,
    url: "https://instagram.com/nft_alienplanet?igshid=OGQ5ZDc2ODk2ZA==",
  },
  // {
  //   icon: <FaTelegramPlane />,
  //   url: "#",
  // },
  // {
  //   thumb: mediumIcon,
  //   url: "#",
  // },
]

export default data
