import { useModal } from "../../../../utils/ModalContext"
import { FiShare2 } from "react-icons/fi"
// import { FaCalendarAlt } from "react-icons/fa"
import BlogDetailsStyleWrapper from "./BlogDestails.style"
// components
import Tag from "../tag/Tag"
// import Comment from "../comment/Comment"
// import CommentBoxment from "../commentBox/CommentBox"
import Sidebar from "../sidebar/Sidebar"

//images
import postThumb from "../../../../assets/images/blog/details/3.png"
// import postThumb2 from "../../../../assets/images/blog/blog-img2.png"
// import postThumb3 from "../../../../assets/images/blog/blog-img3.png"
// import avatarIcon from "../../../../assets/images/blog/user.png"
import postThumb4 from "../../../../assets/images/blog/blog.png"
import postThumb5 from "../../../../assets/images/blog/blog2.png"
import { Link } from "react-router-dom"

const BlogDetails3 = () => {
  const { shareModalHandle } = useModal()
  return (
    <BlogDetailsStyleWrapper>
      <div className='blog_post_details_wrapper'>
        <div className='container'>
          <div className='row'>
            {/* post details col  */}
            <div className='col-lg-8 col-md-12'>
              <div className='post_thumbnail'>
                <img src={postThumb} alt=' nft blog' />
              </div>
              {/* <div className='blog_post_meta'>
                <a href='# ' className='post_author'>
                  <img src={avatarIcon} alt='blog post avatar' />
                  Inna Mouaz
                </a>
                <span>
                  {" "}
                  <FaCalendarAlt /> 18 FEB, 2022
                </span>
              </div> */}
              <h1 className='post_title'>
                What are NFTs and how do they work?
              </h1>
              <div className='blog_description'>
                <div>
                  <p>
                    NFT stands for non-fungible token. In the world of
                    cryptocurrency, fungible tokens are interchangeable. For
                    example, one Bitcoin is equal to another Bitcoin. However,
                    NFTs are unique and cannot be replaced. This makes them
                    ideal for representing digital assets such as artwork,
                    music, and videos.
                  </p>
                  <p>
                    NFTs are stored on a blockchain, which is a distributed
                    ledger that records transactions. This ensures that NFTs are
                    secure and cannot be counterfeited.
                  </p>
                  <p>
                    To create an NFT, you need to first create a digital asset.
                    This could be an image, a video, or a piece of music. Once
                    you have created the digital asset, you need to mint it into
                    an NFT. This process involves uploading the digital asset to
                    a blockchain and creating a unique token for it.
                  </p>
                  <p>
                    Once the NFT is minted, you can sell it or trade it on a
                    marketplace. NFTs can be bought and sold using
                    cryptocurrencies such as Ethereum.
                  </p>
                  <p>
                    NFTs are a relatively new technology, but they are already
                    gaining popularity. They have the potential to revolutionize
                    the way we own and trade digital assets.
                  </p>
                </div>
                <h3>Here are some of the benefits of using NFTs:</h3>
                <h4>Uniqueness:</h4>
                <p>
                  NFTs are unique, which means that they cannot be replaced.
                  This makes them ideal for representing digital assets that are
                  also unique, such as artwork or music.
                </p>
                <h4>Security:</h4>
                <p>
                  NFTs are stored on a blockchain, which is a secure and
                  decentralized ledger. This ensures that NFTs cannot be
                  counterfeited or tampered with. Transparency: The blockchain
                  ledger that stores NFTs is transparent, which means that
                  anyone can view the transaction history for an NFT. This makes
                  it easy to verify the authenticity of an NFT.
                </p>
                <h4>Transparency:</h4>
                <p>
                  The blockchain ledger that stores NFTs is transparent, which
                  means that anyone can view the transaction history for an NFT.
                  This makes it easy to verify the authenticity of an NFT.
                </p>
                <h3>Here are some of the drawbacks of using NFTs:</h3>
                <h4>High cost:</h4>
                <p>
                  The cost of minting an NFT can be high, especially if you are
                  using a popular blockchain such as Ethereum.
                </p>
                <h4>Environmental impact:</h4>
                <p>
                  The mining process for some blockchains, such as Ethereum, can
                  have a significant environmental impact.
                </p>
                <h4>Lack of regulation:</h4>
                <p>
                  The NFT market is still relatively unregulated, which can make
                  it risky for investors.
                </p>
              </div>
              <div>
                <p>
                  Overall, NFTs are a promising new technology with the
                  potential to revolutionize the way we own and trade digital
                  assets. However, there are some risks associated with NFTs,
                  such as the high cost of minting and the lack of regulation.
                </p>
                <p>
                  I hope this blog post has helped you to understand what NFTs
                  are and how they work. If you have any questions, please feel
                  free to leave a comment below.
                </p>
              </div>
              {/* // tags  */}
              <div className='post_tags_list'>
                <Tag />
                <div className='share_butn'>
                  <a href='# ' onClick={(e) => shareModalHandle(e)}>
                    <FiShare2 /> Share
                  </a>
                </div>
              </div>

              {/* related posts  */}
              <div className='related_posts_wrapper'>
                <Link to='/blog-2' className='related_post_item'>
                  <img src={postThumb4} alt=' nft post' />
                  <div className='related_post__title'>
                    <span>PREVIOUS</span>
                    How to Create Your 1st Crypto NFTs 🎉
                  </div>
                </Link>
                <Link to='/blog-4' className='related_post_item next'>
                  <img src={postThumb5} alt=' nft post' />

                  <div className='related_post__title'>
                    <span>Next</span>
                    The new token is launching planet
                  </div>
                </Link>
              </div>

              {/* <div className='post_comments_wrapper'>
                <h4 className='comment_section_title'>3 Comments</h4>
                <Comment />
              </div>

              <CommentBoxment /> */}
            </div>

            {/* **********sidebar*********** */}
            <div className='col-lg-4 col-md-12'>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  )
}

export default BlogDetails3
