import { Link } from "react-router-dom"
import titleShape from "../../../assets/images/icon/home-shape.png"
import PageHeaderStyleWrapper from "./PageHeader.style"
const PageHeader = () => {
  return (
    <PageHeaderStyleWrapper>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-5'>
            <div className='breadcrumb_area'>
              <div className='breadcrumb_menu'>
                <Link to='/home-three'>Home</Link> <span>.</span> ABOUT
                <img
                  className='heading_shape'
                  src={titleShape}
                  alt=' nft heading shape'
                />
              </div>
              <h2 className='breadcrumb_title text-uppercase'>ABOUT US</h2>
            </div>
          </div>
        </div>
      </div>
    </PageHeaderStyleWrapper>
  )
}

export default PageHeader
