import { useModal } from "../../../../utils/ModalContext"
import { FiShare2 } from "react-icons/fi"
// import { FaCalendarAlt } from "react-icons/fa"
import BlogDetailsStyleWrapper from "./BlogDestails.style"
// components
import Tag from "../tag/Tag"
// import Comment from "../comment/Comment"
// import CommentBoxment from "../commentBox/CommentBox"
import Sidebar from "../sidebar/Sidebar"

//images
import postThumb from "../../../../assets/images/blog/details/7.png"
// import postThumb2 from "../../../../assets/images/blog/blog-img2.png"
// import postThumb3 from "../../../../assets/images/blog/blog-img3.png"
// import avatarIcon from "../../../../assets/images/blog/user.png"
import postThumb4 from "../../../../assets/images/blog/blog.png"
import postThumb5 from "../../../../assets/images/blog/blog2.png"
import { Link } from "react-router-dom"

const BlogDetails7 = () => {
  const { shareModalHandle } = useModal()
  return (
    <BlogDetailsStyleWrapper>
      <div className='blog_post_details_wrapper'>
        <div className='container'>
          <div className='row'>
            {/* post details col  */}
            <div className='col-lg-8 col-md-12'>
              <div className='post_thumbnail'>
                <img src={postThumb} alt=' nft blog' />
              </div>
              {/* <div className='blog_post_meta'>
                <a href='# ' className='post_author'>
                  <img src={avatarIcon} alt='blog post avatar' />
                  Inna Mouaz
                </a>
                <span>
                  {" "}
                  <FaCalendarAlt /> 18 FEB, 2022
                </span>
              </div> */}
              <h1 className='post_title'>The environmental impact of NFTs</h1>
              <div className='blog_description'>
                <p>
                  The environmental impact of NFTs is a topic of much debate.
                  Some people argue that NFTs are a waste of energy and
                  resources, while others argue that they have a negligible
                  impact.
                </p>

                <p>
                  The environmental impact of NFTs depends on the blockchain
                  that they are minted on. Some blockchains, such as Ethereum,
                  use a Proof-of-Work (PoW) consensus mechanism, which is very
                  energy-intensive. Other blockchains, such as Solana, use a
                  Proof-of-Stake (PoS) consensus mechanism, which is much more
                  energy-efficient.
                </p>
                <p>
                  The environmental impact of NFTs also depends on the way that
                  they are used. For example, if NFTs are used to represent
                  in-game items, then they could have a significant impact on
                  the environment. However, if NFTs are used to represent music
                  tracks or albums, then their environmental impact would be
                  much lower.
                </p>
                <p>
                  Overall, the environmental impact of NFTs is a complex issue.
                  There is no easy answer, and the impact will vary depending on
                  the specific blockchain and the way that NFTs are used.
                </p>
                <h4>
                  Here are some of the things that can be done to reduce the
                  environmental impact of NFTs:
                </h4>
                <p>
                  Use a blockchain with a more energy-efficient consensus
                  mechanism, such as Solana.
                </p>
                <p>
                  Use NFTs for applications that have a lower environmental
                  impact, such as music tracks or albums. Offset the
                  environmental impact of NFTs by planting trees or investing in
                  renewable energy projects.
                </p>
                <p>
                  Offset the environmental impact of NFTs by planting trees or
                  investing in renewable energy projects.
                </p>
                <p>
                  The NFT market is still relatively unregulated, which can make
                  it risky for investors.
                </p>
                <p>
                  The future of NFTs will depend on how the environmental impact
                  is addressed. If NFTs can be made more environmentally
                  friendly, then they have the potential to be a valuable tool
                  for artists, musicians, and other creators. However, if the
                  environmental impact is not addressed, then NFTs could become
                  a major source of pollution.
                </p>

                <p>
                  I hope this blog post has helped you to understand what NFTs
                  are and how they work. If you have any questions, please feel
                  free to leave a comment below.
                </p>
              </div>
              {/* // tags  */}
              <div className='post_tags_list'>
                <Tag />
                <div className='share_butn'>
                  <a href='# ' onClick={(e) => shareModalHandle(e)}>
                    <FiShare2 /> Share
                  </a>
                </div>
              </div>

              {/* related posts  */}
              <div className='related_posts_wrapper'>
                <Link to='/blog-6' className='related_post_item'>
                  <img src={postThumb4} alt=' nft post' />
                  <div className='related_post__title'>
                    <span>PREVIOUS</span>
                    How to Create Your 1st Crypto NFTs 🎉
                  </div>
                </Link>
                <Link to='/blog-8' className='related_post_item next'>
                  <img src={postThumb5} alt=' nft post' />

                  <div className='related_post__title'>
                    <span>Next</span>
                    The new token is launching planet
                  </div>
                </Link>
              </div>

              {/* <div className='post_comments_wrapper'>
                <h4 className='comment_section_title'>3 Comments</h4>
                <Comment />
              </div>

              <CommentBoxment /> */}
            </div>

            {/* **********sidebar*********** */}
            <div className='col-lg-4 col-md-12'>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  )
}

export default BlogDetails7
