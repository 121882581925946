// import character1 from "../../images/nft/slider-img.png";
// import character2 from "../../images/nft/slider-img2.png";
// import character3 from "../../images/nft/slider-img3.png";
// import character4 from "../../images/nft/slider-img4.png";
// import character5 from "../../images/nft/slider-img5.png";
// import character6 from "../../images/nft/slider-img6.png";
import character1 from "../../images/AP/IMG_5214.JPG"
import character2 from "../../images/AP/IMG_5218.JPG"
import character3 from "../../images/AP/IMG_5219.JPG"
import character4 from "../../images/AP/IMG_5220.JPG"
import character5 from "../../images/AP/IMG_5221.JPG"
import character6 from "../../images/AP/IMG_5222.JPG"
const data = [
  character1,
  character2,
  character3,
  character4,
  character5,
  character6,
  character3,
]

export default data
