import { addDoc, collection } from "firebase/firestore/lite"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { FiX } from "react-icons/fi"
import { toast } from "react-toastify"
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg"
import { useModal } from "../../../utils/ModalContext"
import { firestore } from "../../../utils/firebaseConfig"
import MetamaskModalStyleWrapper from "./MetamaskModal.style"
const AskModal = () => {
  const { askModalHandle } = useModal()

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }
  const newsletterCollectionRef = collection(firestore, "message")
  const handleClick = async () => {
    try {
      const docRef = await addDoc(newsletterCollectionRef, {
        email: email,
        message: message,
      })
      console.log("Entry added with ID: ", docRef.id)
      toast.success("You Have successfully Submitted Message!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setEmail("")
      setMessage("")
      askModalHandle()
    } catch (error) {
      console.error("Error adding entry: ", error)
    }
  }

  return (
    <>
      <MetamaskModalStyleWrapper className='modal_overlay'>
        <div className='mint_modal_box'>
          <div className='mint_modal_content'>
            <div className='modal_header'>
              <button onClick={() => askModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className='modal_body text-center'>
              <div className='footer_newsletter'>
                <div className='form-box'>
                  <div>
                    <input
                      type='text'
                      name='text'
                      placeholder='Email address'
                      required
                      value={email}
                      onChange={handleEmailChange}
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "2px solid rgb(52, 61, 64)",
                        color: "rgba(255, 255, 255, 0.7)",
                        lineHeight: "60px",
                        marginBottom: "20px",
                        padding: "0px 20px",
                      }}
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      name='text'
                      placeholder='Message'
                      required
                      value={message}
                      onChange={handleMessageChange}
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "2px solid rgb(52, 61, 64)",
                        color: "rgba(255, 255, 255, 0.7)",
                        lineHeight: "60px",
                        padding: "0px 20px",
                        marginBottom: "20px",
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      lg
                      style={{
                        lineHeight: "22px",
                        textAlign: "center",
                        fontSize: "16px",
                        textTransform: "uppercase",
                        boxSizing: "border-box",
                        overflow: "hidden",
                        zIndex: "1",
                        width: "200px",
                        height: "60px",
                        background: "rgb(0, 255, 163)",
                        color: "rgb(17, 17, 17)",
                        border: "none",
                      }}
                      variant='mint'
                      className='wishlist_btn'
                      onClick={() => handleClick()}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal_bottom_shape_wrap'>
              <span className='modal_bottom_shape shape_left'>
                <img src={hoverShape} alt=' nft hover shape' />
              </span>
              <span className='modal_bottom_shape shape_right'>
                <img src={hoverShape} alt=' nft hover shape' />
              </span>
            </div>
          </div>
        </div>
      </MetamaskModalStyleWrapper>
    </>
  )
}

export default AskModal
