import CountdownTimer from "react-component-countdown-timer"
import {
  FaDiscord,
  FaTwitter,
  // FaLinkedinIn,
  FaInstagram,
  // FaTelegramPlane,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa"
import Button from "../../../common/button"

import msgIcon from "../../../assets/images/icon/message-favorite.svg"
import openseaIcon from "../../../assets/images/icon/opensea.svg"
// import mediumIcon from "../../../assets/images/icon/med.svg"
import hoverShape from "../../../assets/images/icon/hov_shape_s.svg"
import Particle from "./Particles"
import UpcomingStyleWrapper from "./Upcoming.style"
import { useState } from "react"
import { getFirestore, collection, addDoc } from "firebase/firestore/lite"
import { app } from "../../../utils/firebaseConfig" // Assuming you have already initialized the Firebase app
import { toast } from "react-toastify"

const firestore = getFirestore(app)
const newsletterCollectionRef = collection(firestore, "newsletter")

const Upcoming = () => {
  const targetDate = new Date("September 1, 2023 22:00:00").getTime()

  const calculateRemainingTime = () => {
    const now = new Date().getTime()
    const difference = targetDate - now

    if (difference <= 0) {
      // Countdown has ended
      return 0
    }

    return difference / 1000 // Convert to seconds
  }
  const settings = {
    count: calculateRemainingTime(),
    showTitle: true,
    size: 60,
    labelSize: 14,
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "Days",
    hourTitle: "Hours",
    minuteTitle: "Minutes",
    secondTitle: "Second",
    id: "countdownwrap",
  }

  const [email, setEmail] = useState("")
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleClick = async () => {
    try {
      const docRef = await addDoc(newsletterCollectionRef, { email })
      console.log("Entry added with ID: ", docRef.id)
      toast.success("You Have successfully subscribed to our news letter!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setEmail("")
    } catch (error) {
      console.error("Error adding entry: ", error)
    }
  }
  return (
    <UpcomingStyleWrapper>
      <Particle />
      <div className='container-fluid'>
        <div className='upcoming_content'>
          <h2 className='title'>Alien Planetarium COMING SOON</h2>
          <div className='time_count'>
            <CountdownTimer {...settings} />
            <div className='note'>
              <h5>
                Leave Your Email to Be Notified About Presales & Whitelist
              </h5>
            </div>
          </div>

          <div className='input_box'>
            <input
              type='email'
              placeholder='Email Address'
              value={email}
              onChange={handleEmailChange}
            />
            <button onClick={handleClick}>
              {" "}
              Notify Me <img src={msgIcon} alt='icon' />
            </button>
          </div>

          <div className='cta_btns'>
            <a
              href='https://discord.gg/j8BrPn8SuC'
              target='_blank'
              rel='noopener noreferrer'
            >
              {" "}
              <Button lg variant='outline'>
                {" "}
                <FaDiscord /> Join Discord{" "}
              </Button>
            </a>

            <a
              href='https://twitter.com/NftAlienPlanet?t=TlwjGAYE6RPKi17r0OZyeQ&s=03'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button lg variant='outline'>
                {" "}
                <FaTwitter /> Join Twitter{" "}
              </Button>
            </a>
          </div>
        </div>
        <div className='social_links'>
          <a
            href='http://www.alienplanetarium.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={openseaIcon} alt='icon' />
            <span className='hover_shape'>
              <img src={hoverShape} alt='shape' />
            </span>
          </a>
          <a
            href='https://www.youtube.com/channel/UC_ljTMx-N5JjuphrwnaUEhQ'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaYoutube />
            <span className='hover_shape'>
              <img src={hoverShape} alt='shape' />
            </span>
          </a>
          <a
            href='https://instagram.com/nft_alienplanet?igshid=OGQ5ZDc2ODk2ZA=='
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaInstagram />
            <span className='hover_shape'>
              <img src={hoverShape} alt='shape' />
            </span>
          </a>
          {/* <a href='# '>
            <FaTelegramPlane />
            <span className='hover_shape'>
              <img src={hoverShape} alt='shape' />
            </span>
          </a> */}
          <a
            href='https://www.facebook.com/profile.php?id=100092994216194&mibextid=LQQJ4d'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaFacebook />
            <span className='hover_shape'>
              <img src={hoverShape} alt='shape' />
            </span>
          </a>
          {/* <a href='# '>
            <img src={mediumIcon} alt='icon' />
            <span className='hover_shape'>
              <img src={hoverShape} alt='shape' />
            </span>
          </a> */}
        </div>
      </div>
    </UpcomingStyleWrapper>
  )
}

export default Upcoming
