import { useModal } from "../../../../utils/ModalContext"
import { FiShare2 } from "react-icons/fi"
// import { FaCalendarAlt } from "react-icons/fa"
import BlogDetailsStyleWrapper from "./BlogDestails.style"
// components
import Tag from "../tag/Tag"
// import Comment from "../comment/Comment"
// import CommentBoxment from "../commentBox/CommentBox"
import Sidebar from "../sidebar/Sidebar"

//images
import postThumb from "../../../../assets/images/blog/details/2.png"
// import postThumb2 from "../../../../assets/images/blog/blog-img2.png"
// import postThumb3 from "../../../../assets/images/blog/blog-img3.png"
// import avatarIcon from "../../../../assets/images/blog/user.png"
import postThumb4 from "../../../../assets/images/blog/blog.png"
import postThumb5 from "../../../../assets/images/blog/blog2.png"
import { Link } from "react-router-dom"

const BlogDetails2 = () => {
  const { shareModalHandle } = useModal()
  return (
    <BlogDetailsStyleWrapper>
      <div className='blog_post_details_wrapper'>
        <div className='container'>
          <div className='row'>
            {/* post details col  */}
            <div className='col-lg-8 col-md-12'>
              <div className='post_thumbnail'>
                <img src={postThumb} alt=' nft blog' />
              </div>
              {/* <div className='blog_post_meta'>
                <a href='# ' className='post_author'>
                  <img src={avatarIcon} alt='blog post avatar' />
                  Inna Mouaz
                </a>
                <span>
                  {" "}
                  <FaCalendarAlt /> 18 FEB, 2022
                </span>
              </div> */}
              <h1 className='post_title'>
                NFTs and the Metaverse: Where Digital Possibilities Meet Reality
              </h1>
              <div className='blog_description'>
                <div>
                  <h4>Introduction</h4>
                  <p>
                    The concept of the metaverse has captured the imaginations
                    of millions, promising a seamless blend of digital and
                    physical realities. Central to this virtual universe is the
                    rise of Non-Fungible Tokens (NFTs), which have become a
                    driving force in shaping the metaverse's landscape. In this
                    blog, we embark on a thrilling journey into the metaverse,
                    exploring the transformative power of NFTs in creating
                    immersive experiences, virtual economies, and a new era of
                    interconnectedness.
                  </p>
                </div>
                <h4>
                  NFTs and Virtual Real Estate: Building Your Digital Empire
                </h4>
                <ul>
                  <li>
                    <span>
                      The Metaverse Boom: Understanding the allure of virtual
                      real estate
                    </span>
                  </li>
                  <li>
                    <span>
                      Owning Digital Land: Investing in the future of virtual
                      properties
                    </span>
                  </li>
                  <li>
                    <span>
                      Virtual Cities and Communities: Building connections in
                      the metaverse
                    </span>
                  </li>
                </ul>
                <h4>Avatar NFTs: Embodying Your Virtual Identity</h4>
                <ul>
                  <li>
                    <span>
                      From Selfies to Avatars: Personalizing your virtual
                      presence with NFTs
                    </span>
                  </li>
                  <li>
                    <span>
                      Virtual Identity and Self-Expression: Navigating the
                      metaverse as your digital self
                    </span>
                  </li>
                  <li>
                    <span>
                      Avatar Marketplaces: Trading unique personalities and
                      expressions
                    </span>
                  </li>
                </ul>
                <h4>
                  Virtual Fashion and Wearable NFTs: Crafting Your Digital Style
                </h4>
                <ul>
                  <li>
                    <span>
                      Dressing for the Metaverse: The rise of virtual fashion
                      and wearable NFTs
                    </span>
                  </li>
                  <li>
                    <span>
                      Tokenizing Digital Couture: Owning exclusive digital
                      fashion pieces
                    </span>
                  </li>
                  <li>
                    <span>
                      Fashion Shows and NFTs: Blurring the lines between art and
                      style
                    </span>
                  </li>
                </ul>
                <h4>Play-to-Earn: Gamifying NFT Ownership in the Metaverse</h4>
                <ul>
                  <li>
                    <span>
                      Earning While Gaming: The revolution of play-to-earn games
                    </span>
                  </li>
                  <li>
                    <span>
                      NFT-Backed In-Game Items: Owning rare and valuable assets
                      in virtual worlds
                    </span>
                  </li>
                  <li>
                    <span>
                      NFT Gaming Tournaments: Competing for valuable rewards in
                      the metaverse
                    </span>
                  </li>
                </ul>
                <h4>The Metaverse Economy: NFTs as the Driving Currency</h4>
                <ul>
                  <li>
                    <span>
                      From Cryptocurrencies to Metaverse Coins: The role of NFTs
                      in digital economies
                    </span>
                  </li>
                  <li>
                    <span>
                      Metaverse Marketplaces: Facilitating NFT transactions and
                      exchanges
                    </span>
                  </li>
                  <li>
                    <span>
                      Monetizing Virtual Skills: Exploring new opportunities in
                      the digital workforce
                    </span>
                  </li>
                </ul>
                <h4>
                  Collaboration and Creativity: NFTs Empowering Artists and
                  Creators
                </h4>
                <ul>
                  <li>
                    <span>
                      The NFT Art Movement: Redefining the concept of digital
                      art ownership
                    </span>
                  </li>
                  <li>
                    <span>
                      Collaborative NFT Projects: Fostering a sense of community
                      and shared experiences
                    </span>
                  </li>
                  <li>
                    <span>
                      Empowering Digital Creators: The impact of NFT royalties
                      and resale rights
                    </span>
                  </li>
                </ul>
                <h4>Metaverse Governance: Navigating Digital Democracies</h4>
                <ul>
                  <li>
                    <span>
                      Decentralized Governance: Shaping the rules and
                      regulations of the metaverse
                    </span>
                  </li>
                  <li>
                    <span>
                      Token-Based Voting: Empowering NFT holders to influence
                      virtual decisions
                    </span>
                  </li>
                  <li>
                    <span>
                      Ensuring Transparency and Inclusivity: Building a fair and
                      open metaverse society
                    </span>
                  </li>
                </ul>
                <h4>Conclusion</h4>
                <p>
                  As the metaverse continues to expand and evolve, NFTs are at
                  the forefront, driving innovation and transforming the way we
                  interact with digital environments. From virtual real estate
                  to avatars, gaming, fashion, and art, NFTs empower individuals
                  to claim ownership over their digital experiences. As the
                  metaverse blurs the boundaries between the real and virtual
                  worlds, embracing the potential of NFTs is essential for
                  creating a dynamic and inclusive future where creativity,
                  connectivity, and ownership converge in unprecedented ways.
                  The metaverse beckons, and NFTs are the keys that unlock its
                  boundless possibilities.
                </p>
              </div>

              {/* // tags  */}
              <div className='post_tags_list'>
                <Tag />
                <div className='share_butn'>
                  <a href='# ' onClick={(e) => shareModalHandle(e)}>
                    <FiShare2 /> Share
                  </a>
                </div>
              </div>

              {/* related posts  */}
              <div className='related_posts_wrapper'>
                <Link to='/blog-1' className='related_post_item'>
                  <img src={postThumb4} alt=' nft post' />
                  <div className='related_post__title'>
                    <span>PREVIOUS</span>
                    How to Create Your 1st Crypto NFTs 🎉
                  </div>
                </Link>
                <Link to='/blog-3' className='related_post_item next'>
                  <img src={postThumb5} alt=' nft post' />

                  <div className='related_post__title'>
                    <span>Next</span>
                    The new token is launching planet
                  </div>
                </Link>
              </div>

              {/* <div className='post_comments_wrapper'>
                <h4 className='comment_section_title'>3 Comments</h4>
                <Comment />
              </div>

              <CommentBoxment /> */}
            </div>

            {/* **********sidebar*********** */}
            <div className='col-lg-4 col-md-12'>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  )
}

export default BlogDetails2
