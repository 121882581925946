import GlobalStyles from "../assets/styles/GlobalStyles"
import Layout from "../common/layout"
import About from "../components/section/about/v3"
import Banner from "../components/section/banner/v3"
import CharacterSlider from "../components/section/characterSlider/v3"
import Header from "../components/section/header/v1/Header"
import { useModal } from "../utils/ModalContext"
// import HowToMint from "../components/section/howToMint/v2"
// import RoadMap from "../components/section/roadMap/v3";
import RoadMap from "../components/section/roadMap/v4"
// import Team from "../components/section/team/v3"
import OverlaySpinner from "../common/loader/OverlaySpinner"
import AskModal from "../common/modal/askModal/AskModal"
import ConnectWallet from "../common/modal/metamask/ConnectWallet"
import MetamaskModal from "../common/modal/metamask/MetamaskModal"
import MintNowModal from "../common/modal/mintNowModal"
import WalletModal from "../common/modal/walletModal/WalletModal"
import CTA from "../components/section/cta/v2"
import FAQ from "../components/section/faq/v3"
import Footer from "../components/section/footer/v3"
import MintingInformation from "../components/section/miningInformation/v1"
import NewsLetter from "../components/section/newsLetter"
import { useContext } from "react"
import { TransactionContext } from "../context/transactionContext"

const HomeV3 = () => {
  const {
    visibility,
    walletModalvisibility,
    metamaskModalVisibility,
    connectWalletModal,
    askModalVisibility,
  } = useModal()

  const { loader } = useContext(TransactionContext)
  return (
    <>
      <Layout>
        {loader && <OverlaySpinner />}
        <GlobalStyles />
        {visibility && <MintNowModal />}
        {walletModalvisibility && <WalletModal />}
        {metamaskModalVisibility && <MetamaskModal />}
        {connectWalletModal && <ConnectWallet />}
        {askModalVisibility && <AskModal />}
        <Header />
        <Banner />
        <MintingInformation />
        <CharacterSlider />
        <About />
        {/* <HowToMint /> */}
        <RoadMap />
        {/* <Team /> */}
        <FAQ />
        <NewsLetter />
        <CTA />
        <Footer />
      </Layout>
    </>
  )
}

export default HomeV3
