import { FiCheck, FiMinus } from "react-icons/fi"

const data = [
  {
    title: "Phase 01 - 10%",
    features: [
      {
        icon: <FiCheck />,
        text: "Creation of the CAT NFT collection",
      },
      {
        icon: <FiCheck />,
        text: "Publish Token Name",
      },
      {
        icon: <FiCheck />,
        text: "Launching our 5555 free mint For Whitelist",
      },
      {
        icon: <FiCheck />,
        text: "Development of NFT Marketplace",
      },
      {
        icon: <FiCheck />,
        text: "Project White Paper",
      },
    ],
  },
  {
    title: "Phase 02 - 20%",
    features: [
      {
        icon: <FiCheck />,
        text: "The Solana Blockchain will be Invaded",
      },
      {
        icon: <FiCheck />,
        text: "Collection Launch",
      },
      {
        icon: <FiCheck />,
        text: "Rarity chart, Solrarity and Moonrank",
      },
      {
        icon: <FiCheck />,
        text: "Exploring the idea of a Token",
      },
      {
        icon: <FiCheck />,
        text: "Marketing on social networks",
      },
    ],
  },
  {
    title: "Phase 03 - 30%",
    features: [
      {
        icon: <FiCheck />,
        text: "Dice's & Smart Contract Integration",
      },
      {
        icon: <FiCheck />,
        text: "BTU Public Coin Launch",
      },
      {
        icon: <FiCheck />,
        text: "World Beta Release 1.0",
      },
      {
        icon: <FiMinus />,
        text: "Development of NFT Marketplace",
      },
      {
        icon: <FiMinus />,
        text: "Smart Contract Creation",
      },
      {
        icon: <FiMinus />,
        text: "Project White Paper",
      },
      {
        icon: <FiMinus />,
        text: "Whitepaper Publish",
      },
    ],
  },
  {
    title: "Phase 04 - 10%",
    features: [
      {
        icon: <FiCheck />,
        text: "Creation of the CAT NFT collection",
      },
      {
        icon: <FiCheck />,
        text: "Publish Token Name",
      },
      {
        icon: <FiCheck />,
        text: "Launching our 5555 free mint For Whitelist",
      },
      {
        icon: <FiCheck />,
        text: "Development of NFT Marketplace",
      },
      {
        icon: <FiCheck />,
        text: "Project White Paper",
      },
    ],
  },
  {
    title: "Phase 05 - 20%",
    features: [
      {
        icon: <FiCheck />,
        text: "The Solana Blockchain will be Invaded",
      },
      {
        icon: <FiCheck />,
        text: "Collection Launch",
      },
      {
        icon: <FiCheck />,
        text: "Rarity chart, Solrarity and Moonrank",
      },
      {
        icon: <FiCheck />,
        text: "Exploring the idea of a Token",
      },
      {
        icon: <FiCheck />,
        text: "Marketing on social networks",
      },
    ],
  },
  {
    title: "Phase 06 - 30%",
    features: [
      {
        icon: <FiCheck />,
        text: "The Solana Blockchain will be Invaded",
      },
      {
        icon: <FiCheck />,
        text: "Collection Launch",
      },
      {
        icon: <FiCheck />,
        text: "Rarity chart, Solrarity and Moonrank",
      },
      {
        icon: <FiCheck />,
        text: "Exploring the idea of a Token",
      },
      {
        icon: <FiCheck />,
        text: "Marketing on social networks",
      },
    ],
  },
]

export default data
