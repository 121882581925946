import { FiX } from "react-icons/fi"
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg"
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg"
import { useModal } from "../../../utils/ModalContext"
import WalletModalStyleWrapper from "./WalletModal.style"
import { useState } from "react"
import { useEffect } from "react"
import Button from "../../button"
import { TransactionContext } from "../../../context/transactionContext"
import { useContext } from "react"
const WalletModal = () => {
  const {
    currentAccount,
    connectWallet,
    handleChange,
    sendTransaction,
    formData,
    isLoading,
    errorMsg,
  } = useContext(TransactionContext)

  const { walletModalHandle, connectWalletHandle } = useModal()
  const [metaMaskAvilable, setMetaMaskAvilable] = useState(false)

  useEffect(() => {
    if (
      typeof window.ethereum !== "undefined" &&
      typeof window.ethereum.isMetaMask !== "undefined"
    ) {
      // MetaMask is installed
      console.log("MetaMask is installed!")
      setMetaMaskAvilable(true)
    } else {
      // MetaMask is not installed
      console.log("MetaMask is not installed.")
      setMetaMaskAvilable(false)
    }
  }, [])

  useEffect(() => {
    if (currentAccount) {
      walletModalHandle()
    }
  }, [errorMsg])

  return (
    <>
      <WalletModalStyleWrapper className='modal_overlay'>
        <div className='mint_modal_box'>
          <div className='mint_modal_content'>
            <div className='modal_header'>
              <h2>CONNECT WALLET</h2>
              <button onClick={() => walletModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className='modal_body text-center'>
              <div className='wallet_list'>
                {!metaMaskAvilable ? (
                  <a
                    href='https://metamask.io/download/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img src={metamaskIcon} alt='Meta-mask' />
                    Please install metamask extension!
                  </a>
                ) : (
                  <div>
                    <a onClick={connectWallet} style={{ color: "white" }}>
                      <img src={metamaskIcon} alt='Meta-mask' />
                      Connect with your Metamask!
                    </a>
                    <div style={{ color: "red" }}>{errorMsg}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='modal_bottom_shape_wrap'>
              <span className='modal_bottom_shape shape_left'>
                <img src={hoverShape} alt=' nft hover shape' />
              </span>
              <span className='modal_bottom_shape shape_right'>
                <img src={hoverShape} alt=' nft hover shape' />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  )
}

export default WalletModal
