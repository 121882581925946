import { useContext, useEffect, useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { FaDiscord, FaWallet } from "react-icons/fa"
import { MdNotes } from "react-icons/md"
import { Link } from "react-router-dom"
import Button from "../../../../common/button"
import { isMetaMaskInstalled } from "../../../../config"
import { TransactionContext } from "../../../../context/transactionContext"
import { useModal } from "../../../../utils/ModalContext"
import MobileMenu from "../mobileMenu/MobileMenu"
import NavWrapper from "./Header.style"

const Header = () => {
  const {
    errorMsg,
    currentAccount,
    connectWallet,
    handleChange,
    sendTransaction,
    formData,
    isLoading,
    disConnectWallet,
    getChainId,
  } = useContext(TransactionContext)

  const {
    walletModalHandle,
    metamaskModalHandle,
    account,
    isWalletAlreadyConnected,
    disconnectWalletFromApp,
  } = useModal()
  const [isMobileMenu, setMobileMenu] = useState(false)
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu)
  }

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str
  }

  const handleWalletConnect = async () => {
    if (!isMetaMaskInstalled()) {
      metamaskModalHandle()
    } else {
      walletModalHandle()
    }
  }
  useEffect(() => {
    const header = document.getElementById("navbar")
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky")
      } else {
        header.classList.remove("sticky")
      }
    })

    return () => {
      window.removeEventListener("sticky", handleScroll)
    }
  }, [])

  useEffect(() => {
    isWalletAlreadyConnected()
  }, [isWalletAlreadyConnected])


  return (
    <div>
      <div
        style={{
          background: "red",
          color: "white",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {errorMsg}
      </div>
      <NavWrapper className='bithu_header' id='navbar'>
        <div className='container'>
          {/* Main Menu Start */}
          <div className='bithu_menu_sect'>
            <div className='bithu_menu_left_sect'>
              <div className='logo'>
                <Link to='/'>{/* <img src={logo} alt=' nft logo' /> */}</Link>
              </div>
            </div>
            <div className='bithu_menu_right_sect bithu_v1_menu_right_sect'>
              <div className='bithu_menu_list'>
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>
                    <Link to='/about'>About</Link>
                  </li>
                  <li>
                    <Link to='/roadmap'>Roadmap</Link>
                  </li>
                  {/* <li>
                  <Link to='/team'>Team</Link>
                </li> */}
                  <li>
                    <Link to='/faq'>FAQ</Link>
                  </li>
                  <li>
                    <Link to='/blogs'>Blogs</Link>
                  </li>
                </ul>
              </div>
              <div className='bithu_menu_btns'>
                <button className='menu_btn' onClick={() => handleMobileMenu()}>
                  <MdNotes />
                </button>
                <a
                  href='https://discord.gg/j8BrPn8SuC'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {" "}
                  <Button sm variant='outline' className='join_btn'>
                    <FaDiscord /> Join
                  </Button>
                </a>
                {currentAccount ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='white'
                      id='dropdown-basic'
                      className='connect_btn'
                    >
                      {substr(currentAccount.toString(), 15)}
                    </Dropdown.Toggle>

                    {/* <Dropdown.Menu>
                      <Dropdown.Item href='# ' onClick={() => getChainId()}>
                        Disconnect
                      </Dropdown.Item>
                    </Dropdown.Menu> */}
                  </Dropdown>
                ) : (
                  <Button
                    sm
                    variant='hovered'
                    className='connect_btn'
                    onClick={() => handleWalletConnect()}
                  >
                    <FaWallet />
                    Connect
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* <!-- Main Menu END --> */}
          {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
        </div>
      </NavWrapper>
    </div>
  )
}

export default Header
